/** @format */

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import Loader from 'src/components/Loader';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import { TICKET_MODAL, TICKET_STATUS } from 'src/constants';
import { getTicketDetail } from 'src/slices/tickets';
import { useDispatch, useSelector } from 'src/store';
import { format, formatDistance } from 'date-fns';
import UpdateTicketModal from 'src/components/widget/tickets/updateTicketModal';

const ProgressBar = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={`progress-bar progress-bar-${data.status ? data.status.replace(' ', '-') : ''}`}>
      <ul>
        <li className={`submitted ${data.status === TICKET_STATUS.new || !data.status ? 'active' : ''}`}>
          <p className="number">1</p>
          <p className="status">{t('Tickets.new')}</p>
          <p className="date">{format(new Date(data.createdon), 'dd.MM.yyyy')}</p>
        </li>
        <li className={`in-progress ${data.status === TICKET_STATUS['in progress'] ? 'active' : ''}`}>
          <p className="number">2</p>
          <p className="status">{t('Tickets.inProgress')}</p>
        </li>
        <li className={`in-review ${data.status === TICKET_STATUS.pending ? 'active' : ''}`}>
          <p className="number">3</p>
          <p className="status">{t('Tickets.pending')}</p>
        </li>
        <li className={`on-hold ${data.status === TICKET_STATUS.resolved ? 'active' : ''}`}>
          <p className="number">4</p>
          <p className="status">{t('Tickets.resolved')}</p>
          {data.closedon && <p className="date">{format(new Date(data.closedon), 'dd.MM.yyyy')}</p>}
        </li>

        {data.status === TICKET_STATUS.cancelled ? (
          <li className={`cancelled ${data.status === TICKET_STATUS.cancelled ? 'active' : ''}`}>
            <p className="number">5</p>
            <p className="status">{t('Tickets.cancelled')}</p>
          </li>
        ) : (
          <li className={`closed ${data.status === TICKET_STATUS.closed ? 'active' : ''}`}>
            <p className="number">5</p>
            <p className="status">{t('Tickets.closed')}</p>
          </li>
        )}
      </ul>
    </div>
  );
};

const Comment = ({ data }) => {
  return (
    <div className="comment">
      <div className="comment-header">
        {/* <div className="comment-name">
          <img src={data.avatar} alt="" />
          <span>{data.name}</span>
        </div> */}
        <div className="comment-date">{formatDistance(new Date(data.createdon), new Date(), { addSuffix: true })}</div>
      </div>
      <div className="comment-detail" dangerouslySetInnerHTML={{ __html: data.notetext }} />
    </div>
  );
};

const TicketInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="ticket-info">
      <div className="ticket-title">
        <p>
          <strong>{t('Tickets.caseTitle')}</strong>
        </p>
        <p>{data.title}</p>
      </div>
      <div className="ticket-description">
        <p>
          <strong>{t('Tickets.caseDescription')}</strong>
        </p>
        <p>{data.description}</p>
      </div>
    </div>
  );
};

const StarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6503 9.04L14.8103 8.62L12.9203 4.17C12.5803 3.36 11.4203 3.36 11.0803 4.17L9.19032 8.63L4.36032 9.04C3.48032 9.11 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.97L20.2103 10.79C20.8803 10.21 20.5303 9.11 19.6503 9.04ZM12.0003 15.4L8.24032 17.67L9.24032 13.39L5.92032 10.51L10.3003 10.13L12.0003 6.1L13.7103 10.14L18.0903 10.52L14.7703 13.4L15.7703 17.68L12.0003 15.4Z"
      fill="#234D96"
    />
  </svg>
);

export default function TicketDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { ticketDetail } = useSelector(state => state.tickets);
  const [modalOpened, setModalOpened] = React.useState(null);

  React.useEffect(() => {
    const Storage = window.localStorage;
    const lang = Storage.i18nextLng || 'en';
    dispatch(getTicketDetail(lang, id));
  }, []);

  const toggleUpdateTicketModal = incidentid => {
    if (modalOpened === TICKET_MODAL.UPDATE_TICKET) {
      setModalOpened(null);
    } else {
      setModalOpened(TICKET_MODAL.UPDATE_TICKET);
    }
  };
  const handleBack = () => {
    if (typeof location.key !== 'undefined') history.goBack();
    else history.push(`/tickets`);
  };

  return (
    <Page className={`tickets`} title="Tickets">
      <div className="container">
        <button className="btn-back" onClick={handleBack}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.93534 12.7715C5.45038 12.3715 5.45038 11.6285 5.93534 11.2285L13.8637 4.68933C14.516 4.15137 15.5 4.61532 15.5 5.46078L15.5 18.5392C15.5 19.3847 14.516 19.8486 13.8637 19.3107L5.93534 12.7715Z"
              fill="#234D96"
            />
          </svg>
          <span>{t('Tickets.back')}</span>
        </button>
        {ticketDetail && ticketDetail.incidentid === id ? (
          <>
            <h1 className="tickets-title">{ticketDetail.ticketnumber}</h1>
            <h2 className="tickets-sub-title">{t('Tickets.caseDetail')}</h2>
            <ProgressBar data={ticketDetail} />
            <div className="ticket-main">
              <TicketInfo data={ticketDetail} />
              <div className="ticket-right-side">
                <div className="ticket-shadow-box ticket-comments">
                  <p>
                    <strong>{t('Tickets.comments')}</strong>
                  </p>
                  <div className="comments-list">
                    {[...ticketDetail.comments]
                      .sort((a, b) => new Date(a.createdon) - new Date(b.createdon))
                      .map((data, index) => (
                        <Comment data={data} key={index} />
                      ))}
                  </div>
                  {ticketDetail.status !== TICKET_STATUS.closed && (
                    <div className="comment-btn">
                      <button onClick={() => toggleUpdateTicketModal(ticketDetail.incidentid)}>
                        <span>{t('Tickets.newComment')}</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <UpdateTicketModal
              incidentid={ticketDetail.incidentid}
              ticketnumber={ticketDetail.ticketnumber}
              open={modalOpened === TICKET_MODAL.UPDATE_TICKET}
              onClose={() => setModalOpened(null)}
            />
          </>
        ) : (
          <LoadingScreen />
        )}
      </div>
    </Page>
  );
}
