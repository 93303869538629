/** @format */

import React from 'react';
import { Menu } from 'antd';
import styles from './globalHeader.module.scss';
import { IS_PRODUCTION } from 'src/constants';

export const menuAppDropDown = lang => {
  const headerLinks = {
    myVDOPortal: IS_PRODUCTION ? 'https://my.contiautomotive.com' : 'https://staging.my.contiautomotive.com',
    vdoFleet: '#',
    fleetsWebsite: {
      de: '#',
      en: '#',
      'de-AT': '#',
      fr: '#',
      it: '#',
      es: '#',
      'en-GB': '#',
      nl: '#'
    },
    vdoShop: {
      de: IS_PRODUCTION ? '#' : '#',
      en: IS_PRODUCTION ? '#' : '#',
      'de-AT': IS_PRODUCTION ? '#' : '#',
      fr: IS_PRODUCTION ? '#' : '#',
      it: IS_PRODUCTION ? '#' : '#',
      es: IS_PRODUCTION ? '#' : '#',
      'en-GB': IS_PRODUCTION ? '#' : '#',
      nl: IS_PRODUCTION ? '#' : '#',
      pl: IS_PRODUCTION ? '#' : '#'
    },
    continentalLMS: 'https://continental.litmoseu.com/account/login/?',
    extranet: IS_PRODUCTION
      ? 'https://extranet.continental-partner.com/'
      : 'https://staging.extranet.continental-partner.com/'
  };

  return [
    {
      key: '1',
      label: (
        <a href={headerLinks.myVDOPortal} target="_blank" rel="noopener noreferrer">
          Customer Portal
        </a>
      )
    },
    {
      key: '2',
      label: (
        <a href={headerLinks.vdoFleet} target="_blank" rel="noopener noreferrer">
          VDO Fleet
        </a>
      )
    },
    {
      key: '3',
      label: (
        <a
          href={headerLinks.fleetsWebsite[lang] || headerLinks.fleetsWebsite.en}
          target="_blank"
          rel="noopener noreferrer">
          Fleets website
        </a>
      )
    },
    {
      key: '4',
      label: (
        <a href={headerLinks.vdoShop[lang] || headerLinks.vdoShop.en} target="_blank" rel="noopener noreferrer">
          VDO Shop
        </a>
      )
    },
    {
      key: '5',
      label: (
        <a href={headerLinks.continentalLMS} target="_blank" rel="noopener noreferrer">
          Continental LMS
        </a>
      )
    },
    {
      key: '6',
      label: (
        <a href={headerLinks.extranet} target="_blank" rel="noopener noreferrer">
          Extranet
        </a>
      )
    }
  ];
};

export const AppsDropdown = props => {
  return (
    <Menu className={styles.menuDropDown}>
      <Menu.Item key="0">
        <a
          href={IS_PRODUCTION ? 'https://my.contiautomotive.com' : 'https://staging.my.contiautomotive.com'}
          target="_blank"
          rel="noopener noreferrer">
          Customer Portal
        </a>
      </Menu.Item>

      <Menu.Item key="1">
        <a href="#" target="_blank" rel="noopener noreferrer">
          TIS-Web
        </a>
      </Menu.Item>

      <Menu.Item key="2">
        <a href="#" target="_blank" rel="noopener noreferrer">
          Fleets website
        </a>
      </Menu.Item>

      <Menu.Item key="3">
        <a href="#" target="_blank" rel="noopener noreferrer">
          Conti Shop
        </a>
      </Menu.Item>

      <Menu.Item key="4">
        <a href="#" target="_blank" rel="noopener noreferrer">
          Conti Academy
        </a>
      </Menu.Item>

      <Menu.Item key="5">
        <a
          href={
            IS_PRODUCTION
              ? 'https://extranet.continental-partner.com/'
              : 'https://staging.extranet.continental-partner.com/'
          }
          target="_blank"
          rel="noopener noreferrer">
          Extranet
        </a>
      </Menu.Item>
    </Menu>
  );
};
