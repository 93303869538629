/** @format */

import { Tooltip } from '@material-ui/core';
import React from 'react';
import { Button } from 'src/components';

export default function Components() {
  return (
    <div style={{ padding: 24 }}>
      <h1>Primary</h1>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: 'flex',
          gap: 16,
          flexWrap: 'wrap',
          alignItems: 'center',
          fontSize: '20px'
        }}>
        <Tooltip arrow title="primary" placement="top">
          <div>
            <Button label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary noArrow" placement="top">
          <div>
            <Button noArrow label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary disabled" placement="top">
          <div>
            <Button disabled label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary borderOnly" placement="top">
          <div>
            <Button borderOnly label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary borderOnly noArrow" placement="top">
          <div>
            <Button borderOnly noArrow label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary borderOnly disabled" placement="top">
          <div>
            <Button borderOnly disabled label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary borderOnly check" placement="top">
          <div>
            <Button borderOnly check label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="primary borderOnly check disabled" placement="top">
          <div>
            <Button borderOnly check disabled label="Button" />
          </div>
        </Tooltip>
      </div>
      <h1>Secondary</h1>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: 'flex',
          gap: 16,
          flexWrap: 'wrap',
          alignItems: 'center',
          fontSize: '20px'
        }}>
        <Tooltip arrow title="secondary" placement="top">
          <div>
            <Button secondary label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary noArrow" placement="top">
          <div>
            <Button secondary noArrow label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary disabled" placement="top">
          <div>
            <Button secondary disabled label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary borderOnly" placement="top">
          <div>
            <Button secondary borderOnly label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary borderOnly noArrow" placement="top">
          <div>
            <Button secondary borderOnly noArrow label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary borderOnly disabled" placement="top">
          <div>
            <Button secondary borderOnly disabled label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary borderOnly check" placement="top">
          <div>
            <Button secondary borderOnly check label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="secondary borderOnly check disabled" placement="top">
          <div>
            <Button secondary borderOnly check disabled label="Button" />
          </div>
        </Tooltip>
      </div>
      <h1>Tertiary</h1>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: 'flex',
          gap: 16,
          flexWrap: 'wrap',
          alignItems: 'center',
          fontSize: '20px'
        }}>
        <Tooltip arrow title="tertiary" placement="top">
          <div>
            <Button tertiary label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="tertiary disabled" placement="top">
          <div>
            <Button tertiary disabled label="Button" />
          </div>
        </Tooltip>
      </div>
      <h1>Ghost</h1>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: 'flex',
          gap: 16,
          flexWrap: 'wrap',
          alignItems: 'center',
          fontSize: '20px'
        }}>
        <Tooltip arrow title="ghost" placement="top">
          <div>
            <Button ghost label="Button" />
          </div>
        </Tooltip>
        <Tooltip arrow title="ghost disabled" placement="top">
          <div>
            <Button ghost disabled label="Button" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
