/** @format */

export const getBreakpoint = () => {
  if (window.innerWidth >= 1920) return 'xxl'; // 1920 -
  if (window.innerWidth >= 1440) return 'xl'; // 1440 - 1919
  if (window.innerWidth >= 1024) return 'lg'; // 1024 - 1439
  if (window.innerWidth >= 768) return 'md'; // 768 - 1023
  if (window.innerWidth >= 576) return 'sm'; // 576 - 767
  if (window.innerWidth >= 375) return 'xs'; // 375 - 575
  return 'xs'; // 0 - 375
};

const commonSize = (breakpoint, item) => {
  if (breakpoint === 'md')
    return {
      w: (item.w > 6 ? 2 : 1) || 1,
      h: 4,
      minW: 1,
      maxW: 2,
      minH: 2,
      maxH: 6
    };
  if (breakpoint === 'sm') return { w: 1, h: 4, minW: 1, maxW: 1, minH: 2, maxH: 6 };
  if (breakpoint === 'xs') return { w: 1, h: 4, minW: 1, maxW: 1, minH: 2, maxH: 6 };
};

export const calcSize = (size, item) => {
  const breakpoint = getBreakpoint();
  switch (size) {
    case 'small':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 3, h: 2, minW: 2, maxW: 4, minH: 2, maxH: 2 };
      }
      return { w: 1, h: 2, minW: 1, maxW: 2, minH: 1, maxH: 2 };
    case 'normal':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 3, h: 4, minW: 3, maxW: 9, minH: 2, maxH: 6 };
      }
      return commonSize(breakpoint, item);
    case 'wide':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 4, h: 4, minW: 3, maxW: 8, minH: 1, maxH: 4 };
      }
      return commonSize(breakpoint, item);
    case 'medium':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 4, h: 4, minW: 3, maxW: 8, minH: 1, maxH: 4 };
      }
      return commonSize(breakpoint, item);
    case 'large':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 6, h: 4, minW: 3, maxW: 8, minH: 1, maxH: 4 };
      }
      return commonSize(breakpoint, item);
    case 'xwide':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 8, h: 4, minW: 3, maxW: 10, minH: 1, maxH: 4 };
      }
      return commonSize(breakpoint, item);
    case 'fullWidth':
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 12, h: 4, minW: 3, maxW: 12, minH: 1, maxH: 4 };
      }
      return commonSize(breakpoint, item);
    default:
      if (['xxl', 'xl', 'lg'].includes(breakpoint)) {
        return { w: 4, h: 2, minW: 3, maxW: 5, minH: 1, maxH: 4 };
      }
      return commonSize(breakpoint, item);
  }
};

export const calculateItemPositions = (widgets, totalWidthOfItems) => {
  const lessThanLG = window.innerWidth < 1024;
  const positions = [];
  let currentX = 0;
  let currentY = 0;
  let maxHeightInRow = 0;

  if (widgets.length) {
    widgets.forEach((item, i) => {
      const itemWidth = calcSize(item.size, item).w;
      const itemHeight = calcSize(item.size, item).h;

      const CONTACT_WIDGET = 'contact';
      if (
        item.type === CONTACT_WIDGET &&
        widgets[i - 1] &&
        widgets[i - 1].type &&
        widgets[i - 1].type === CONTACT_WIDGET
      ) {
        currentX -= calcSize(widgets[i - 1].size, widgets[i - 1]).w;
        currentY += calcSize(widgets[i - 1].size, widgets[i - 1]).h;
      } else if (currentX + itemWidth > (lessThanLG ? 2 : 12)) {
        if (
          widgets[i - 2] &&
          calcSize(widgets[i - 1].size, widgets[i - 1]).h < calcSize(widgets[i - 2].size, widgets[i - 2]).h
        ) {
          // currentX = 0;
        } else {
          currentX = 0;
        }
        currentY += maxHeightInRow;
        maxHeightInRow = 0;
      }

      positions.push({
        x: currentX,
        y: currentY
      });

      currentX += itemWidth;
      if (itemHeight > maxHeightInRow) {
        maxHeightInRow = itemHeight;
      }
    });
  }
  return positions;
};
