/** @format */

import clsx from 'clsx';
import React from 'react';

export const Button = props => {
  const {
    className,
    additionalClassName,
    children,
    label,
    secondary,
    noArrow,
    check,
    borderOnly,
    tertiary,
    ghost,
    back,
    ...rest
  } = props;
  return (
    <button
      className={clsx(
        className || `primary__green-triangle`,
        secondary && 'secondary',
        borderOnly && 'border-only',
        noArrow && 'no-arrow',
        check && 'check',
        back && 'back',
        tertiary && 'tertiary',
        ghost && 'ghost',
        additionalClassName
      )}
      {...rest}>
      {label || children}
    </button>
  );
};
