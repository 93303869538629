/** @format */

import * as React from 'react';
import { Link } from 'react-router-dom';
import CreateTicketModal from './createTicketModal';
import Ticket from './ticket';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import UpdatePostalCodeModal from './updatePostalCodeModal';
import { useDispatch, useSelector } from 'src/store';
import { setUserData } from 'src/slices/users';
import UpdateTicketModal from './updateTicketModal';
import { TICKET_MODAL } from 'src/constants';
import CloseTicketModal from './closeTicketModal';
import CreateTicketForTollingModal from './createTicketForTollingModal';
import CreateTicketForMoDModal from './createTicketForMoDModal';
import { getTickets } from 'src/slices/tickets';

export default function TicketsWidget({ data, lang }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [modalOpened, setModalOpened] = React.useState(null);
  const [currentUpdateTicketId, setCurrentUpdateTicketId] = React.useState(null);
  const { userData } = useSelector(state => state.users);
  const { ticketLoading } = useSelector(state => state.tickets);
  const { tickets } = useSelector(state => state.tickets);

  React.useEffect(() => {
    if (user && Object.keys(user).length > 0 && ((userData && Object.keys(userData).length === 0) || !userData)) {
      dispatch(setUserData(user));
    }
  }, [user]);
  React.useEffect(() => {
    if (tickets === null) {
      dispatch(getTickets(lang));
    }
  }, [tickets]);

  const handleOpenCreateTicketModal = () => {
    if (!userData?.address?.postalCode || !userData?.company) {
      setModalOpened(TICKET_MODAL.UPDATE_POSTALCODE);
    } else {
      setModalOpened(TICKET_MODAL.CREATE_TICKET);
    }
  };
  const toggleUpdateTicketModal = incidentid => {
    if (modalOpened === TICKET_MODAL.UPDATE_TICKET) {
      setModalOpened(null);
      setCurrentUpdateTicketId(null);
    } else {
      setModalOpened(TICKET_MODAL.UPDATE_TICKET);
      setCurrentUpdateTicketId(incidentid);
    }
  };
  const toggleCancelTicketModal = incidentid => {
    if (modalOpened === TICKET_MODAL.CLOSE_TICKET) {
      setModalOpened(null);
      setCurrentUpdateTicketId(null);
    } else {
      setModalOpened(TICKET_MODAL.CLOSE_TICKET);
      setCurrentUpdateTicketId(incidentid);
    }
  };

  return (
    <div className="tickets-widget" data-width={data.w} data-height={data.h}>
      <div className="tickets-container">
        <div className="tickets-header">
          <h3 className="tickets-heading" data-name="">
            {data.title}
          </h3>
        </div>

        {!ticketLoading && tickets ? (
          <>
            {tickets.length > 0 ? (
              <ol className="tickets-table">
                <li className="ticket ticket-container">
                  <div className="attribute">{t('Tickets.caseID')}</div>
                  <div className="attribute">{t('Tickets.title')}</div>
                  <div className="attribute">{t('Tickets.submitted')}</div>
                  <div className="attribute">{t('Tickets.status')}</div>
                  <div className="attribute"></div>
                </li>
                {[...tickets]
                  .sort((a, b) => new Date(b.createdon) - new Date(a.createdon))
                  .map((ticket, i) => (
                    <Ticket
                      ticket={ticket}
                      key={ticket.incidentid + i}
                      toggleUpdateTicketModal={toggleUpdateTicketModal}
                      toggleCancelTicketModal={toggleCancelTicketModal}
                    />
                  ))}
              </ol>
            ) : (
              <p className="tickets-widget--list-empty">{t('widget.noTickets')}</p>
            )}
            <div className="tickets-links">
              <Link to={`/tickets`} className="tickets-link-all">
                <span>{t('Tickets.allCases')}</span>
              </Link>
              <Link to={`#`} className="tickets-link-all" onClick={handleOpenCreateTicketModal}>
                <span>{t('Tickets.newSupportCase')}</span>
              </Link>
            </div>
          </>
        ) : (
          <LoadingScreen />
        )}
      </div>
      <CreateTicketForMoDModal open={modalOpened === TICKET_MODAL.CREATE_TICKET} onClose={() => setModalOpened(null)} />
      {/* {userData && userData.scenario === 'tolling-proxy-customer' ? (
        <CreateTicketForTollingModal
          open={modalOpened === TICKET_MODAL.CREATE_TICKET}
          onClose={() => setModalOpened(null)}
        />
      ) : userData && userData.scenario && userData.scenario.includes('maps-on-demand') ? (
        <CreateTicketForMoDModal
          open={modalOpened === TICKET_MODAL.CREATE_TICKET}
          onClose={() => setModalOpened(null)}
        />
      ) : (
        userData && (
          <CreateTicketModal open={modalOpened === TICKET_MODAL.CREATE_TICKET} onClose={() => setModalOpened(null)} />
        )
      )} */}
      <UpdatePostalCodeModal
        open={modalOpened === TICKET_MODAL.UPDATE_POSTALCODE}
        onClose={() => setModalOpened(null)}
        onUpdatePostalCodeSuccess={() => setModalOpened(TICKET_MODAL.CREATE_TICKET)}
      />
      <UpdateTicketModal
        incidentid={currentUpdateTicketId}
        open={modalOpened === TICKET_MODAL.UPDATE_TICKET}
        onClose={() => {
          setModalOpened(null);
          toggleUpdateTicketModal(null);
        }}
      />
      <CloseTicketModal
        open={modalOpened === TICKET_MODAL.CLOSE_TICKET}
        onClose={() => {
          setModalOpened(null);
          toggleCancelTicketModal(null);
        }}
        incidentid={currentUpdateTicketId}
      />
    </div>
  );
}
