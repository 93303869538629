/** @format */

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/store';

export default function SurveyModalStep1({ setStep }) {
  const { userData } = useSelector(state => state.users);
  const { t } = useTranslation();
  return (
    <Grid className="survey-step">
      <img className="survey-step-img" src="/static/home/survey_1.png" alt="" width={296} height={164} />
      <Typography className="survey-step-title">{t('homeSurvey.step1Title')}</Typography>
      <Typography className="survey-step-content">{t('homeSurvey.step1Content')}</Typography>
      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={!userData}
          secondary
          borderOnly
          onClick={() => setStep(2)}
          label={<label>{t('homeSurvey.step1Btn')}</label>}
          additionalClassName="survey-step-btn"
        />
      </Grid>
    </Grid>
  );
}
