/** @format */

import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem, Checkbox, Box } from '@material-ui/core';
import { Button } from 'src/components';
// import {ArrowRight} from '@material-ui/icons';
import { Avatar } from 'src/components/globalHeader/avatar';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router-dom';
import Map from './map';
import { useEffect } from 'react';
import {
  deleteUserProfilePicture,
  updateUserInfo,
  updateUserProfilePicture,
  uploadUserProfilePicture
} from 'src/slices/users';
import { useDispatch, useSelector } from 'src/store';
import Breadcrumb from 'src/components/Breadcrumb';
import { UploadIcon } from 'src/components/icons';
import { countriesMap } from 'src/components/utils/dataMap';
import prettyBytes from 'pretty-bytes';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1.5rem',
    //maxWidth:"70%",
    margin: '0 auto'
  },
  textColor: {
    font: '300 14px/15px ContinentalStagSansW-L',
    fontFamily: 'ContinentalStagSansW-L, "Noto Sans"',
    marginLeft: '32px'
    //backgroundColor:'black'
  },
  btnwrapper: {
    marginTop: 15
  },
  linkcolor: {
    color: '#08C476',
    fontSize: '3.5rem',
    verticalAlign: 'middle'
  },
  checkbox: {
    color: '#868E96',
    paddingLeft: '0',
    // fontSize: "3.5rem",
    // verticalAlign: "middle",
    '& .MuiSvgIcon-root': {
      height: '14px',
      width: '14px'
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#000000'
    }
  },
  btnStyle: {
    border: '2px solid #333',
    borderRadius: 5,
    fontSize: '1.2rem',
    color: '#FFF',
    fontWeight: '700',
    background: '#000',
    padding: 10,
    '&:hover': {
      background: 'transparent',
      border: '2px solid #333',
      color: '#333'
    }
  },
  delbtnStyle: {
    marginLeft: '1.5rem',
    borderRadius: 25,
    fontSize: '1.2rem',
    color: '#333',
    fontWeight: '700',
    '&:hover': {
      background: 'transparent'
    }
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    '& > *': {
      font: '400 18px/18px ContinentalStagSansW',
      fontFamily: 'ContinentalStagSansW, "Noto Sans"',
      color: '#000000'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        borderBottom: '1px solid #FEA500'
      },
      '&.Mui-focused': {
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #FEA500'
        },
        '&:after': {
          borderBottom: '1px solid #FEA500'
        }
      },
      '&:after': {
        borderBottom: '1px solid #FEA500'
      }
    },
    '& > .Mui-focused.MuiInputLabel-formControl': {
      color: '#FEA500'
    }
  },
  footerBtn: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '20px'
  },
  abortBtn: {
    marginLeft: '1.5rem',
    borderRadius: 25,
    fontSize: '1.2rem',
    color: '#333',
    fontWeight: '700',
    '&:hover': {
      background: 'transparent'
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    minWidth: '100%',
    '& > *': {
      fontSize: '1.5rem',
      color: '#333'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        borderBottom: '2px solid #234D96'
      }
    },
    '& > .MuiInputLabel-formControl': {
      color: '#234D96'
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontSize: '1.5rem'
  },
  cardHead: {
    // marginTop: "2rem"
  },
  profileImg: {
    display: 'flex',
    alignItems: 'center'
  },
  profileImgBtns: {
    marginLeft: '152px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '20px'
  },
  hidden: {
    display: 'none'
  },
  actionButton: {
    font: '500 20px/38px ContinentalStagSansW-M',
    fontFamily: 'ContinentalStagSansW-M, "Noto Sans"',
    padding: '8px 32px',
    border: 'none',
    background: '#FEA500',
    textTransform: 'initial'
  }
}));

const PersonalData = props => {
  const { user } = useAuth();
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [gender, setGender] = useState('man');
  const [userData, setUserData] = useState(null);
  const { error, userProfile, userLoading } = useSelector(state => state.users);
  const [errorMessage, setErrorMessage] = useState({ error: error });
  const [userProfilePicture, setUserProfilePicture] = useState('');
  const { t } = useTranslation();
  const inputFileRef = useRef();
  const [successMessage, setSuccessMessage] = useState(null);
  // const [country, setCountry] = useState('Germany');
  const goBack = () => {
    history.push('/profile');
  };
  const handleChange = event => {
    setGender(event.target.value);
  };
  // const handleChangeCountry = (event) => {
  //     setCountry(event.target.value);
  // };
  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  useEffect(() => {
    if (userProfile && userProfile.picture && !userProfilePicture) {
      setUserProfilePicture(userProfile.picture);
    }
  }, [userProfile]);

  useEffect(() => {
    if (error) {
      const errorMsg = error.error
        .replace('The mime type of the file is invalid', t('personalData.profile_errors.mime_1'))
        .replace('Allowed mime types are', t('personalData.profile_errors.mime_2'))
        .replace('An image for this user already exists.', t('personalData.profile_errors.already_exists'))
        .replace('Unable to save image.', t('personalData.profile_errors.unable_save'))
        .replace('No image uploaded.', t('personalData.profile_errors.no_image_uploaded'))
        .replace('The file was only partially uploaded.', t('personalData.profile_errors.was_partially_uploaded'))
        .replace('No file was uploaded.', t('personalData.profile_errors.no_file_uploaded'))
        .replace('The file could not be uploaded.', t('personalData.profile_errors.file_could_not_be_uploaded'));
      setErrorMessage({ ...errorMessage, avatar: errorMsg });
      setUserProfilePicture('');
      inputFileRef.current.value = null;
    }
  }, [error]);

  const setData = e => {
    const { name, value } = e.target;
    setUserData(stats => ({
      ...stats,
      [name]: value
    }));
  };
  const setCheck = e => {
    const { name, checked } = e.target;
    setUserData(stats => ({
      ...stats,
      [name]: checked
    }));
  };
  const setAddress = e => {
    const { name, value } = e.target;
    let address = { ...userData.address, ...{ [name]: value } };
    setUserData(stats => ({
      ...stats,
      address
    }));
  };
  const setLatLong = (lat, long) => {
    // debugger

    let newLocation = userData;
    newLocation.location.lat = lat;
    newLocation.location.long = long;

    setUserData(newLocation);
  };
  const saveProfileData = async () => {
    const { firstName, lastName, company, address, phoneNumber } = userData;
    setErrorMessage({
      ...errorMessage,
      firstName: firstName ? null : t('personalData.errorMessage.firstName'),
      lastName: lastName ? null : t('personalData.errorMessage.lastName'),
      company: company ? null : t('personalData.errorMessage.company'),
      phoneNumber: address.phoneNumber ? null : t('personalData.errorMessage.phoneNumber'),
      address: address.street && address.postalCode && address.city ? null : t('personalData.errorMessage.address')
    });
    if (
      firstName &&
      lastName &&
      company &&
      address.street &&
      address.postalCode &&
      address.city &&
      address.phoneNumber
    ) {
      setErrorMessage({ error: null });
      setSuccessMessage(null);
      await dispatch(updateUserInfo(userData));
      setSuccessMessage(t('personalData.successMessage'));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      setSuccessMessage(null);
    }
  };

  const breadcrumbData = [
    {
      title: t('profile.title'),
      link: '/profile',
      options: [
        {
          title: t('profile.title'),
          link: '/profile'
        },
        {
          title: t('personalData.title'),
          link: '/personaldata'
        },
        {
          title: t('Access.title'),
          link: '/access'
        }
      ]
    },
    {
      title: t('personalData.title')
    }
  ];

  const onChangeProFilePicture = async e => {
    // setErrorMessage(null);
    setSuccessMessage(null);
    try {
      const [file] = e.target.files;
      if (file) {
        const { size } = file;
        if (size / (1024 * 1024) > 2) {
          const sizePretty = prettyBytes(size);
          setErrorMessage({
            ...errorMessage,
            avatar: t('personalData.profile_errors.maxSize', { fileSize: sizePretty, limit: '2 MB' })
          });
          return;
        }

        const picture = URL.createObjectURL(file);
        setUserProfilePicture(picture);
        e.target.value = null;

        if (userProfilePicture) await dispatch(deleteUserProfilePicture());

        const data = new FormData();
        data.append('file', file);

        await dispatch(uploadUserProfilePicture({ data }));
        setSuccessMessage(t('personalData.successMessage'));
        setErrorMessage({
          ...errorMessage,
          avatar: ''
        });
        await dispatch(updateUserProfilePicture());
      }
    } catch (error) {
      setErrorMessage({
        ...errorMessage,
        avatar: 'Something went wrong!'
      });
      // console.log(error);
    }
  };

  const deleteUserProfile = async () => {
    await dispatch(deleteUserProfilePicture());
    inputFileRef.current.value = null;
    setUserProfilePicture('');
  };

  const checkErrorMessage = () => {
    return Object.keys(errorMessage).some(key => {
      return errorMessage[key] !== null && errorMessage[key] !== '';
    });
  };

  const role = useMemo(() => {
    if (user && Object.keys(user).length > 0) {
      const { scenario, role, roleOther, roles } = user;
      if (roleOther) return roleOther;
      if (role) return t(`personalData.roles.${role}`);
      if (scenario) return t(`personalData.scenario.${scenario}`);
      if (roles && roles.length > 0) return roles[0];
      return 'Fleet Manager';
    }
    return '';
  }, [user, t]);

  return (
    <section className="main_content profile">
      <Breadcrumb data={breadcrumbData} />

      <div className=" container">
        {checkErrorMessage(errorMessage) ? (
          <div className="errorMessage">
            <span>{t('personalData.errorMessage.overall')}</span>
          </div>
        ) : (
          successMessage && (
            <div className="successMessage">
              <span>{successMessage}</span>
            </div>
          )
        )}

        <h2 className="page_title">{t('personalData.title')}</h2>
        <h4 className="page_subtitle">{t('profile.subTitle')}</h4>
        {/* <Grid xs={0} sm={1} md={1} lg={1} xl={1}></Grid> */}
        <div className="personal-data-container">
          <Box>
            <h4 className="sub_head">{t('personalData.profilepic')}</h4>
            <Box className={classes.profileImg}>
              <Box className="profile_pic">
                <Avatar srcImg={userProfilePicture} name={`${userData?.firstName} ${userData?.lastName}`} />
              </Box>
              <Box className={classes.textColor}>
                <div dangerouslySetInnerHTML={{ __html: t('personalData.pictext') }}></div>
                {errorMessage.avatar && <p className="page_error">{errorMessage.avatar}</p>}
              </Box>
            </Box>
            <Box className={classes.profileImgBtns}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <input
                  ref={inputFileRef}
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  disabled={userLoading}
                  onChange={onChangeProFilePicture}
                  id="profilePictureUploadBtn"
                  style={{ display: 'none' }}
                />
                <label htmlFor="profilePictureUploadBtn" className={`button ${classes.actionButton}`}>
                  <UploadIcon />
                  <Box component="span" sx={{ ml: '10px' }}>
                    {t('personalData.uploadBtn')}
                  </Box>
                </label>
              </Box>
              <Box>
                {userProfilePicture && (
                  <Button
                    disabled={userLoading}
                    label={t('personalData.deleteBtn')}
                    className="btn-trans"
                    onClick={deleteUserProfile}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: '112px' }}>
            <h4 className="sub_head">{t('personalData.title')}</h4>
            <Box className={classes.cardHead}>
              {/* <Grid item xs={12}> */}
              <FormControl className={classes.formControl && classes.hidden}>
                <InputLabel id="gender">{t('personalData.gender.title')}</InputLabel>
                <Select labelId="gender" id="gender" value={gender} onChange={handleChange}>
                  <MenuItem value="man">{t('personalData.gender.man')}</MenuItem>
                  <MenuItem value="woman">{t('personalData.gender.woman')}</MenuItem>
                  <MenuItem value="others">{t('personalData.gender.others')}</MenuItem>
                </Select>
              </FormControl>
              {/* </Grid> */}
              {/* <Grid item xs={12}> */}
              <TextField
                style={{ backgroundColor: errorMessage.firstName ? '#ffe0e1' : '' }}
                id="standard-full-width"
                label={t('personalData.firstName')}
                placeholder={t('personalData.firstName')}
                value={userData?.firstName || ''}
                fullWidth
                className={classes.textField}
                onChange={setData}
                name="firstName"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errorMessage.firstName && <div className="errorMessageInput">{errorMessage.firstName}</div>}
              {/* </Grid> */}
              {/* <Grid item xs={12}> */}
              <TextField
                style={{ backgroundColor: errorMessage.lastName ? '#ffe0e1' : '' }}
                id="standard-full-width"
                label={t('personalData.Surname')}
                placeholder={t('personalData.Surname')}
                fullWidth
                value={userData?.lastName}
                className={classes.textField}
                onChange={setData}
                name="lastName"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errorMessage.lastName && <div className="errorMessageInput">{errorMessage.lastName}</div>}
              {/* </Grid>
                            <Grid item xs={12}> */}
              <TextField
                id="standard-full-width"
                label={t('personalData.position')}
                placeholder={t('personalData.position')}
                fullWidth
                value={role}
                className={classes.textField}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ readOnly: true }}
              />
              {/* </Grid>

                            <Grid item xs={12}> */}
              <TextField
                style={{ backgroundColor: errorMessage.company ? '#ffe0e1' : '' }}
                id="standard-full-width"
                label={t('personalData.company')}
                placeholder={t('personalData.company')}
                fullWidth
                className={classes.textField}
                margin="normal"
                value={userData?.company}
                onChange={setData}
                name="company"
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errorMessage.company && <div className="errorMessageInput">{errorMessage.company}</div>}
              {/* </Grid> */}
              {/* <Grid item xs={12}>
                                <span style={{fontSize:"14px", color:"#999"}}>* {t('personalData.Requiredfield')}</span>
                            </Grid> */}
              {/* <Grid item xs={12}>
                            <div className={classes.footerBtn}>
                                <Button onClick={goBack} className={classes.abortBtn}>{t('personalData.Abortbtn')}</Button>
                                {''}
                                <Button className={classes.btnStyle} variant="outlined" color="primary">{t('personalData.saveBtn')}</Button>
                            </div>
                            </Grid> */}
            </Box>
          </Box>

          <Box sx={{ mt: '104px' }}>
            <h4 className="sub_head">{t('personalData.companyaddress')}</h4>
            <Box className={classes.cardHead}>
              {/* <Grid item xs={12}> */}
              <TextField
                style={{ backgroundColor: errorMessage.address ? '#ffe0e1' : '' }}
                id="standard-full-width"
                label={t('personalData.Street')}
                placeholder={t('personalData.Street')}
                fullWidth
                className={classes.textField}
                margin="normal"
                value={(userData && userData.address)?.street}
                onChange={setAddress}
                name="street"
                InputLabelProps={{
                  shrink: true
                }}
              />
              {/* </Grid>  */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ backgroundColor: errorMessage.address ? '#ffe0e1' : '' }}
                    id="standard-full-width"
                    label={t('personalData.Postcode')}
                    placeholder={t('personalData.Postcode')}
                    className={classes.textField}
                    value={(userData && userData.address)?.postalCode}
                    onChange={setAddress}
                    name="postalCode"
                    margin="normal"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ backgroundColor: errorMessage.address ? '#ffe0e1' : '' }}
                    id="standard-full-width"
                    label={t('personalData.city')}
                    placeholder={t('personalData.city')}
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    value={(userData && userData.address)?.city}
                    onChange={setAddress}
                    name="city"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="standard-full-width"
                    label={t('personalData.country')}
                    placeholder={t('personalData.country')}
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    value={userData?.address?.country ? countriesMap.get(userData.address.country) : ''}
                    onChange={setAddress}
                    name="country"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>

                {/* <FormControl className={classes.formControl}>
                                    <InputLabel id="country">{t('personalData.country')}</InputLabel>
                                    <Select
                                    labelId="country"
                                    id="country"
                                    value={(user && user.address && user)?.address.country}
                                    // value={country}
                                    onChange={handleChangeCountry}
                                    >
                                    <MenuItem value="en">Germany</MenuItem>
                                    <MenuItem value="India">India</MenuItem>
                                    <MenuItem value="Australia">Australia</MenuItem>
                                    </Select>
                                </FormControl> */}
                <Grid item xs={12}>
                  {errorMessage.address && <div className="errorMessageInput">{errorMessage.address}</div>}
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ mt: '104px' }}>
            <h4 className="sub_head">{t('personalData.Contact_Information')}</h4>
            <Grid item xs={12}>
              <TextField
                style={{ backgroundColor: errorMessage.phoneNumber ? '#ffe0e1' : '' }}
                id="standard-full-width"
                label={t('personalData.phone')}
                placeholder={t('personalData.phone')}
                className={classes.textField}
                value={(userData && userData.address)?.phoneNumber}
                onChange={setAddress}
                name="phoneNumber"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errorMessage.phoneNumber && <div className="errorMessageInput">{errorMessage.phoneNumber}</div>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-full-width"
                label={t('personalData.Faxnumber')}
                placeholder={t('personalData.Faxnumber')}
                className={classes.textField}
                value={(userData && userData.address)?.faxNumber}
                onChange={setAddress}
                name="faxNumber"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Box>

          <Box sx={{ mt: '104px' }}>
            <h4 className="sub_head">{t('personalData.Additional_Information')}</h4>
            <Grid item xs={12}>
              <span style={{ fontSize: '14px', color: '#999' }}>* {t('personalData.Mandatory_field')}</span>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="standard-full-width"
                label={t('personalData.VAT_Number')}
                placeholder={t('personalData.VAT_Number')}
                fullWidth
                value={userData?.vatNumber}
                onChange={setData}
                name="vatNumber"
                className={classes.textField}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-full-width"
                label={t('personalData.TCO_Number')}
                placeholder={t('personalData.TCO_Number')}
                fullWidth
                value={userData?.sealNumber}
                onChange={setData}
                name="sealNumber"
                className={classes.textField}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-full-width"
                label={t('personalData.Diesel_repair_service_id')}
                placeholder={t('personalData.Diesel_repair_service_id')}
                fullWidth
                className={classes.textField}
                value={userData?.drsId}
                onChange={setData}
                name="drsId"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-full-width"
                label={t('personalData.ATE_Break_Center_ID')}
                placeholder={t('personalData.ATE_Break_Center_ID')}
                fullWidth
                value={userData?.brakeCenterId}
                onChange={setData}
                name="brakeCenterId"
                className={classes.textField}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: '56px' }}>
              <Checkbox
                className={classes.checkbox}
                name="newsletter"
                checked={userData ? userData.newsletter : false}
                onChange={setCheck}
              />
              <p className="checkbox-text">{t('personalData.newsletter')}</p>
            </Box>
            <Grid item xs={12}>
              <div className={classes.footerBtn}>
                <Button onClick={goBack} className="btn-trans" label={t('personalData.Abortbtn')} />
                <Button
                  className={`button ${classes.actionButton}`}
                  onClick={saveProfileData}
                  label={t('personalData.saveBtn')}
                />
              </div>
            </Grid>
          </Box>

          {/* <Box sx={{ mt: '104px' }}>
            <h4 className="sub_head mb-10">{t('personalData.mapHeading')}</h4>
            <p className="map-text">{t('personalData.Maptext')}</p>
            <Grid container className={classes.cardHead}>
              <Grid item xs={12}>
                <Map
                  lat={(userData && userData.location)?.lat}
                  long={(userData && userData.location)?.long}
                  setLatLong={setLatLong}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.footerBtn}>
                  <Button
                    className="btn-trans"
                    label={t('personalData.deleteMapBtn')}
                    onClick={() => {
                      setUserData(prevData => ({ ...prevData, location: { lat: null, long: null } }));
                    }}
                  />
                  <Button
                    className={`button ${classes.actionButton}`}
                    onClick={saveProfileData}
                    label={t('personalData.saveMapBtn')}
                  />
                </div>
              </Grid>
            </Grid>
          </Box> */}
        </div>
      </div>
    </section>
  );
};

export { PersonalData };
