/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getRedirectionLink from 'src/utils/helper';

export function NewsWidget({ data }) {
  const { t } = useTranslation();
  return (
    <div className="news-widget" data-width={data.w} data-height={data.h}>
      <div className="news-widget--title">
        <h2>{data.title}</h2>
      </div>
      {data.news && (
        <div className="news-widget--list-wrapper">
          <div className="news-widget--list">
            {data.news.length > 0 ? (
              data.news.map((item, index) => {
                const { nid, title, date, category, content } = item;
                if (item.link)
                  return (
                    <a
                      href={getRedirectionLink(item)}
                      target={`${item.linkTarget === '_blank' ? '_blank' : ''}`}
                      className="news-widget--list-item"
                      key={index}>
                      <div className="news-widget--list-item-top">
                        <div className="news-widget--list-item-title">{title}</div>
                        {/* <div className="news-widget--list-item-date-category">
                        <div className="news-widget--list-item-date">{date || '00.00.0000'}</div>
                        <div className="news-widget--list-item-category">{category || 'category'}</div>
                      </div> */}
                      </div>
                      <div className="news-widget--list-item-text">{content}</div>
                    </a>
                  );
                return (
                  <Link to={getRedirectionLink(item)} className="news-widget--list-item" key={nid}>
                    <div className="news-widget--list-item-top">
                      <div className="news-widget--list-item-title">{title}</div>
                      {/* <div className="news-widget--list-item-date-category">
                        <div className="news-widget--list-item-date">{date || '00.00.0000'}</div>
                        <div className="news-widget--list-item-category">{category || 'category'}</div>
                      </div> */}
                    </div>
                    <div className="news-widget--list-item-text">{content}</div>
                  </Link>
                );
              })
            ) : (
              <p className="news-widget--list-empty">{t('widget.noNews')}</p>
            )}
          </div>
        </div>
      )}
      {/* <div className="news-widget--link">
        <a href="/news">{t('widget.allNews')}</a>
      </div> */}
    </div>
  );
}
