/** @format */

import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../button';
import SelectComponent from '../select';
import { useTranslation } from 'react-i18next';
import { countryOptions } from 'src/utils/dataMap';
import { useDispatch, useSelector } from 'src/store';
import { setUserData, updateUserInfo } from 'src/slices/users';
import { scenarioRequiredFields } from 'src/views/survey';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    '& > *': {
      fontSize: 18,
      color: '#333'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        borderBottom: '2px solid black'
      },
      '&:after': {
        borderBottom: '2px solid black'
      }
    },
    '& > .MuiInputLabel-formControl': {
      color: 'black'
    }
  }
}));
const validationFields = ['postalCode', 'street', 'city', 'company', 'country'];

export default function SurveyModalStep3({ setStep, group }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);
  const [isValidated, setIsValidated] = useState(true);
  const [errors, setErrors] = useState({});

  const scenario = userData.scenario || '';

  const validate = () => {
    const _errors = {};
    if (!userData?.address?.postalCode) _errors.postalCode = true;
    if (scenarioRequiredFields[scenario].includes('mainFields')) {
      if (!userData?.address?.street) _errors.street = true;
      if (!userData?.address?.city) _errors.city = true;
      if (!userData?.company) _errors.company = true;
    }
    if (scenarioRequiredFields[scenario].includes('country') && !userData?.address?.country) {
      _errors.country = true;
    }
    setErrors(_errors);
    return _errors;
  };
  const handleOnBlur = e => {
    const _errors = { ...errors };
    _errors[e.target.name] = validationFields.includes(e.target.name) && e.target.value === '';
    setErrors(_errors);
  };

  const handleConfirmData = () => {
    const _errors = validate();
    if (Object.keys(_errors).length === 0) setStep(4);
  };

  return (
    <Grid className="survey-step survey-step3">
      <Typography className="survey-step-step">{t('homeSurvey.step3Step')}</Typography>
      {group !== 'conti-internal' && (
        <>
          <Typography className="survey-step-title">{t('homeSurvey.step3Title')}</Typography>
          <Typography className="survey-step-content">{t('homeSurvey.step3Content')}</Typography>
        </>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            error={errors.company || false}
            name="company"
            onBlur={handleOnBlur}
            label={`${t('homeSurvey.step3FieldBusiness')}${
              scenarioRequiredFields[scenario].includes('mainFields') ? '*' : ''
            }`}
            value={userData.company || ''}
            className={classes.textField}
            onChange={e => dispatch(setUserData({ ...userData, company: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            error={errors.country || false}
            name="country"
            onBlur={handleOnBlur}
            label={`${t('homeSurvey.step3SelectCountry')}${
              scenarioRequiredFields[scenario].includes('country') ? '*' : ''
            }`}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address
                    ? { ...userData.address, country: e.target.value }
                    : { country: e.target.value }
                })
              )
            }
            value={userData?.address?.country || ''}
            options={countryOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors.street || false}
            name="street"
            onBlur={handleOnBlur}
            label={`${t('homeSurvey.step3FieldStreet')}${
              scenarioRequiredFields[scenario].includes('mainFields') ? '*' : ''
            }`}
            className={classes.textField}
            value={userData?.address?.street || ''}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address
                    ? { ...userData.address, street: e.target.value }
                    : { street: e.target.value }
                })
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={errors.postalCode || false}
            name="postalCode"
            onBlur={handleOnBlur}
            label={`${t('homeSurvey.step3FieldZipcode')}*`}
            value={userData?.address?.postalCode || ''}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address
                    ? { ...userData.address, postalCode: e.target.value }
                    : { postalCode: e.target.value }
                })
              )
            }
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={errors.city || false}
            name="city"
            onBlur={handleOnBlur}
            label={`${t('homeSurvey.step3FieldCity')}${
              scenarioRequiredFields[scenario].includes('mainFields') ? '*' : ''
            }`}
            value={userData?.address?.city || ''}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address ? { ...userData.address, city: e.target.value } : { city: e.target.value }
                })
              )
            }
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('homeSurvey.step3FieldMail')}
            value={userData?.address?.postBox || ''}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address
                    ? { ...userData.address, postBox: e.target.value }
                    : { postBox: e.target.value }
                })
              )
            }
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('homeSurvey.step3FieldPhone')}
            value={userData?.address?.phoneNumber || ''}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address
                    ? { ...userData.address, phoneNumber: e.target.value }
                    : { phoneNumber: e.target.value }
                })
              )
            }
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('homeSurvey.step3FieldFax')}
            value={userData?.address?.faxNumber || ''}
            onChange={e =>
              dispatch(
                setUserData({
                  ...userData,
                  address: userData.address
                    ? { ...userData.address, faxNumber: e.target.value }
                    : { faxNumber: e.target.value }
                })
              )
            }
            className={classes.textField}
          />
        </Grid>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 64, gap: 16 }}>
        <Button onClick={() => setStep(2)} borderOnly secondary noArrow label={<label>{t('Tickets.back')}</label>} />
        <Button
          onClick={handleConfirmData}
          borderOnly
          secondary
          check
          label={<label>{t('homeSurvey.step3Btn')}</label>}
        />
      </Grid>
    </Grid>
  );
}
