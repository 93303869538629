/** @format */

import { DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import CancelCreateTicketModal from './cancel';
import SuccessCreateTicket from './success';
import ErrorCreateTicket from './error';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import useAuth from 'src/hooks/useAuth';
import SelectComponent from 'src/components/select';
import { DeleteIcon, UploadIcon } from 'src/components/icons';
import { IS_PRODUCTION, VALIDATE_REGEX } from 'src/constants';
import { filesize } from 'filesize';
import { useDispatch, useSelector } from 'src/store';
import { clearTicketError, createTicket, getTickets } from 'src/slices/tickets';
import { getFileBase64 } from 'src/utils/helper';

const initPostData = {
  attachmentFileName: null,
  title: '',
  contactName: '',
  contactEmail: '',
  dealerAssociation: '',
  customerCompanyName: '',
  affectedEmailAddress: '',
  affectedVehicle: '',
  affectedDriver: '',
  softwareVersion: '',
  deviceserialnumber: '',
  vehicleRegistrationNumber: '',
  companyCardNumber: '',
  description: '',
  ava_endcustomer: '',
  ava_endcustomercontact: '',
  requestType: null,
  impact: null,
  urgency: null,
  typeOfProblem: null,
  component: null,
  feature: null,
  model: null,
  type: null,
  attachment: null
};
const impactData = [
  { value: 'Significant', name: 'Significant' },
  { value: 'Moderate', name: 'Moderate' },
  { value: 'Minor', name: 'Minor' }
];
const urgencyData = [
  { value: 'High', name: 'High' },
  { value: 'Medium', name: 'Medium' },
  { value: 'Low', name: 'Low' }
];

export default function CreateTicketModal({ open, onClose }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOtherPerson, setIsOtherPerson] = React.useState(false);
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [postData, setPostData] = React.useState(initPostData);
  // const [isValidated, setIsValidated] = React.useState(false);
  const [validations, setValidations] = React.useState({});
  const [componentData, setComponentData] = React.useState([]);
  const [featureData, setFeatureData] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);
  const { updateTicketLoading, error: errorTicket } = useSelector(state => state.tickets);

  React.useEffect(() => {
    if (userData) {
      setPostData({
        ...postData,
        customerId: userData.company || 'B2C dummy',
        customerCompanyName: userData.company,
        accountName: userData.firstName + ' ' + userData.lastName,
        zip: userData.address?.postalCode || userData.address?.postalCode || '',
        contactName: userData.firstName + ' ' + userData.lastName,
        contactEmail: userData.email,
        affectedEmailAddress: userData.email,
        ava_endcustomer: userData.firstName + ' ' + userData.lastName
      });
      if (userData.roles?.includes('admin')) setIsAdmin(true);
    }
  }, [open, user, userData]);
  React.useEffect(() => {
    if (success && !updateTicketLoading) {
      setPostData(initPostData);
    }
  }, [success, updateTicketLoading]);

  // Set component data dropdown
  React.useEffect(() => {
    setValidations({});
    switch (postData.typeOfProblem) {
      case 'VDO Fleet':
        setComponentData([
          { value: 'VDO Fleet Login', name: 'VDO Fleet Login' },
          { value: 'Admin', name: 'Admin' },
          { value: 'Connect', name: 'Connect' },
          { value: 'VDO Fleet App', name: 'VDO Fleet App' },
          { value: 'Extract', name: 'Extract' },
          { value: 'LiveData', name: 'LiveData' },
          { value: 'LiveMaps (Mobile)', name: 'LiveMaps (Mobile)' },
          { value: 'Motion', name: 'Motion' },
          { value: 'Tachograph Management', name: 'Tachograph Management' },
          { value: 'Remote Download', name: 'Remote Download' }
        ]);
        break;
      case 'Hardware':
        setComponentData([
          { value: 'R-DL 4G', name: 'R-DL 4G' },
          { value: 'DLD SR', name: 'DLD SR' },
          { value: 'DLD WR', name: 'DLD WR' },
          { value: 'Downloadkey', name: 'Downloadkey' },
          { value: 'ProConnect', name: 'ProConnect' },
          { value: 'Smart Link', name: 'Smart Link' },
          { value: 'Smart Terminal', name: 'Smart Terminal' }
        ]);
        break;
      default:
        break;
    }
  }, [postData.typeOfProblem]);
  // Change isOtherPerson checkbox
  React.useEffect(() => {
    setValidations({});
    if (isOtherPerson) {
      setPostData(newPostData => ({
        ...newPostData,
        customerCompanyName: '',
        contactName: '',
        contactEmail: ''
      }));
    } else if (userData) {
      setPostData(newPostData => ({
        ...newPostData,
        customerCompanyName: userData.company,
        contactName: userData.firstName + ' ' + userData.lastName,
        contactEmail: userData.email
      }));
    }
  }, [isOtherPerson]);
  // Change request type
  React.useEffect(() => {
    setValidations({});
  }, [postData.requestType]);

  const validate = React.useCallback(() => {
    setValidations({});
    let _validations = {};
    if (!postData.ava_endcustomer) _validations = { ..._validations, ava_endcustomer: true };
    if (isOtherPerson) {
      if (!postData.contactName) _validations = { ..._validations, contactName: true };
      if (!postData.contactEmail || !VALIDATE_REGEX.email.test(postData.contactEmail))
        _validations = { ..._validations, contactEmail: true };
    }
    switch (postData.requestType) {
      case 'service':
        // STEP 1
        if (!postData.typeOfProblem) {
          if (!postData.title) {
            _validations = { ..._validations, title: true };
          }
          if (!postData.impact) {
            _validations = { ..._validations, impact: true };
          }
          if (!postData.urgency) {
            _validations = { ..._validations, urgency: true };
          }
        }
        // STEP 2
        if (postData.typeOfProblem) {
          if (!postData.component) {
            _validations = { ..._validations, component: true };
          }
          if (!postData.customerCompanyName) {
            _validations = { ..._validations, customerCompanyName: true };
          }
          if (!postData.affectedEmailAddress || !VALIDATE_REGEX.email.test(postData.affectedEmailAddress)) {
            _validations = { ..._validations, affectedEmailAddress: true };
          }
          if (!postData.description) {
            _validations = { ..._validations, description: true };
          }

          if (postData.component) {
            if (
              postData.typeOfProblem === 'VDO Fleet' &&
              postData.component !== 'VDO Fleet App' &&
              !postData.affectedDriver
            ) {
              _validations = { ..._validations, affectedDriver: true };
            }
            if (postData.typeOfProblem === 'Hardware' && !postData.deviceserialnumber) {
              _validations = { ..._validations, deviceserialnumber: true };
            }
          }
        }
        break;
      case 'sales':
      case 'logistics':
        if (!postData.customerCompanyName) {
          _validations = { ..._validations, customerCompanyName: true };
        }
        if (!postData.affectedEmailAddress || !VALIDATE_REGEX.email.test(postData.affectedEmailAddress)) {
          _validations = { ..._validations, affectedEmailAddress: true };
        }
        if (!postData.title) {
          _validations = { ..._validations, title: true };
        }
        if (!postData.description) {
          _validations = { ..._validations, description: true };
        }
        break;
      default:
        break;
    }
    setValidations(_validations);
    return _validations;
  }, [postData]);

  const updatePostData = (name, value) => {
    setPostData(newPostData => ({ ...newPostData, [name]: value }));
    if (name === 'requestType' && value === 'service') {
      setPostData(newPostData => ({
        ...newPostData,
        component: null,
        urgency: null,
        impact: null
      }));
    }
    if (name === 'requestType' && value === 'logistics') {
      setPostData(newPostData => ({
        ...newPostData,
        component: 'Logistics/Accounting request',
        urgency: 'low',
        impact: 'minor'
      }));
    }
    if (name === 'requestType' && value === 'sales') {
      setPostData(newPostData => ({
        ...newPostData,
        component: 'Sales request',
        urgency: 'low',
        impact: 'minor'
      }));
    }
    // Validation after update
    if (
      name === 'customerCompanyName' ||
      name === 'ava_endcustomer' ||
      name === 'contactName' ||
      name === 'title' ||
      name === 'title' ||
      name === 'impact' ||
      name === 'urgency' ||
      name === 'description' ||
      (name === 'deviceserialnumber' && postData.typeOfProblem === 'Hardware') ||
      (name === 'affectedDriver' && postData.typeOfProblem === 'VDO Fleet')
    ) {
      setValidations(validations => ({
        ...validations,
        [name]: value === ''
      }));
    }
    if (name === 'contactEmail' || name == 'affectedEmailAddress') {
      setValidations(validations => ({
        ...validations,
        [name]: value === '' || !VALIDATE_REGEX.email.test(value)
      }));
    }
  };
  const handleSelectFiles = async e => {
    setFiles(files.concat(Array.from(e.target.files)));
    if (e.target.files && e.target.files[0]) {
      const attachmentFileName = e.target.files[0].name;
      const attachment = await getFileBase64(e.target.files[0]);
      setPostData({ ...postData, attachmentFileName, attachment });
    }
    // e.target.files = [];
    // e.target.value = null;
  };
  const removeFile = index => {
    files.splice(index, 1);
    setFiles([...files]);
    setPostData({ ...postData, attachmentFileName: null, attachment: null });
  };
  const closeNewTicket = () => {
    if (!updateTicketLoading) {
      onClose(false);
      setCancelModalOpen(false);
      dispatch(clearTicketError());

      // avoid flash of layout
      setTimeout(() => {
        setPostData(initPostData);
        setValidations({});
        setError(false);
        setSuccess(false);
        setFiles([]);
      }, 300);
    }
  };
  const handleClose = () => {
    if (!updateTicketLoading) {
      if (success) closeNewTicket();
      else setCancelModalOpen(true);
    }
  };
  const goToStep2 = typeOfProblem => {
    const _validations = validate();
    if (Object.keys(_validations).length === 0) {
      updatePostData('typeOfProblem', typeOfProblem);
    }
  };
  const handleSubmit = async () => {
    const _validations = validate();
    if (Object.keys(_validations).length === 0) {
      const createTicketPostData = {
        title: IS_PRODUCTION ? postData.title : `(TEST) ${postData.title}`,
        description: postData.description,
        customerid: postData.customerId,
        accountName: postData.customerCompanyName,
        zip: postData.zip,
        contactName: postData.contactName || postData.ava_endcustomer,
        contactEmail: postData.contactEmail,
        project: postData.component,
        impact: postData.impact,
        urgency: postData.urgency,
        smy_affecteddriver: postData.affectedDriver || null,
        smy_Project: null,
        smy_SubProject: null,
        smy_deviceserialnumber: postData.deviceserialnumber,
        smy_companycardnumber: postData.companyCardNumber,
        smy_model: postData.model,
        smy_softwareversion: postData.softwareVersion,
        components: null,
        ava_endcustomer: isOtherPerson ? postData.ava_endcustomer : null,
        ava_endcustomercontact: isOtherPerson ? postData.affectedEmailAddress : null,
        attachment: postData.attachment,
        attachmentFileName: postData.attachmentFileName,
        vrnnumber: postData.component === 'Motion/Maps' ? postData.affectedVehicle : postData.vehicleRegistrationNumber
      };
      await dispatch(createTicket(createTicketPostData));
      setError(false);
      setSuccess(true);

      const Storage = window.localStorage;
      const lang = Storage.i18nextLng || 'en';
      await dispatch(getTickets(lang));
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: 10
          }
        }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogActions>
          <IconButton onClick={handleClose} disabled={updateTicketLoading}>
            <CloseIcon style={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <div className="create-ticket-body">
            {errorTicket ? (
              <ErrorCreateTicket closeModal={closeNewTicket} />
            ) : error ? (
              <ErrorCreateTicket closeModal={closeNewTicket} />
            ) : success ? (
              <SuccessCreateTicket closeModal={closeNewTicket} />
            ) : (
              <>
                <Typography className="create-ticket-title">{t('Tickets.newTicket')}</Typography>
                {postData.typeOfProblem === null && (
                  <>
                    <div className="contact-person-heading">{t('Tickets.contactPerson')}</div>
                    {user && (
                      <div className="contact-person">
                        <div className="contact-person-left">
                          <p className="contact-name">
                            {userData.firstName} {userData.lastName}
                          </p>
                          <p className="contact-email">{userData.email}</p>
                          <p className="contact-tel">{userData.address?.phoneNumber || ''}</p>
                        </div>
                        <div className="contact-person-right">
                          <p className="contact-address">{userData.company}</p>
                          {userData.address && (
                            <>
                              <p className="contact-street">
                                {userData.address.street} {userData.address.postBox}
                              </p>
                              <p className="contact-city">
                                {userData.address.country} {userData.address.postalCode} {userData.address.city}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="contact-person-checkbox">
                      {/* TEMPORARY HIDDEN */}
                      {/* <div className="contact-person-checkbox-flex">
                        <input
                          type="checkbox"
                          id="contact-person-checkbox"
                          checked={isOtherPerson}
                          onChange={() => setIsOtherPerson(!isOtherPerson)}
                        />
                        <label htmlFor="contact-person-checkbox">{t('Tickets.checkboxLabelNewTicket')}</label>
                      </div> */}
                      {isOtherPerson && (
                        <Grid container style={{ marginTop: 10 }}>
                          <Grid item xs={12}>
                            <TextField
                              // className="product-detail-input"
                              className="create-ticket-text-field"
                              fullWidth
                              id="other-person-name"
                              label={`${t('Tickets.name')} *`}
                              variant="standard"
                              style={{ marginBottom: 10 }}
                              onChange={e => updatePostData('contactName', e.target.value)}
                              value={postData.contactName}
                              error={validations.contactName || null}
                            />
                            <TextField
                              // className="product-detail-input"
                              className="create-ticket-text-field"
                              fullWidth
                              id="other-person-email"
                              label={`${t('Tickets.email')} *`}
                              variant="standard"
                              onChange={e => updatePostData('contactEmail', e.target.value)}
                              value={postData.contactEmail}
                              error={validations.contactEmail || null}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <SelectComponent
                            label="Request type *"
                            options={[
                              { value: 'service', name: 'Service/Support & Product Request' },
                              { value: 'sales', name: 'Sales request' },
                              { value: 'logistics', name: 'Logistics/Accounting request' }
                            ]}
                            onChange={e => updatePostData('requestType', e.target.value)}
                            value={postData.requestType}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {postData.requestType && postData.requestType === 'service' ? (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Title *"
                                onChange={e => updatePostData('title', e.target.value)}
                                value={postData.title || ''}
                                error={validations.title || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Dealer Association"
                                onChange={e => updatePostData('dealerAssociation', e.target.value)}
                                value={postData.dealerAssociation || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Impact *"
                                options={impactData}
                                onChange={e => updatePostData('impact', e.target.value)}
                                value={postData.impact}
                                error={validations.impact || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Urgency *"
                                options={urgencyData}
                                onChange={e => updatePostData('urgency', e.target.value)}
                                value={postData.urgency}
                                error={validations.urgency || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {postData.typeOfProblem === null && (
                          <div className="create-ticket-btns-step-1">
                            <Button borderOnly onClick={() => goToStep2('VDO Fleet')}>
                              VDO Fleet
                            </Button>
                            <Button borderOnly onClick={() => goToStep2('Hardware')}>
                              Hardware
                            </Button>
                          </div>
                        )}
                      </>
                    ) : postData.requestType && postData.requestType !== 'service' ? (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                disabled={!isOtherPerson}
                                className="create-ticket-text-field"
                                label="Customer Company Name *"
                                onChange={e => updatePostData('customerCompanyName', e.target.value)}
                                value={postData.customerCompanyName || ''}
                                error={validations.customerCompanyName || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label={`${t('Tickets.name')} *`}
                                onChange={e => updatePostData('ava_endcustomer', e.target.value)}
                                value={postData.ava_endcustomer || ''}
                                error={validations.ava_endcustomer || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Affected Email Address *"
                                onChange={e => updatePostData('affectedEmailAddress', e.target.value)}
                                value={postData.affectedEmailAddress || ''}
                                error={validations.affectedEmailAddress || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Title *"
                                onChange={e => updatePostData('title', e.target.value)}
                                value={postData.title || ''}
                                error={validations.title || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 30 }}>
                              <TextField
                                className="create-ticket-textarea"
                                multiline
                                label="Description *"
                                onChange={e => updatePostData('description', e.target.value)}
                                value={postData.description || ''}
                                variant="outlined"
                                minRows={4}
                                error={validations.description || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
                {/* STEP 2 */}
                {postData.typeOfProblem !== null && (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <SelectComponent
                            label="Component *"
                            options={componentData}
                            onChange={e => updatePostData('component', e.target.value)}
                            value={postData.component}
                            error={validations.component || null}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <TextField
                            disabled={!isOtherPerson}
                            className="create-ticket-text-field"
                            label="Customer Company Name *"
                            onChange={e => updatePostData('customerCompanyName', e.target.value)}
                            value={postData.customerCompanyName || ''}
                            error={validations.customerCompanyName || null}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <TextField
                            className="create-ticket-text-field"
                            label="Affected Email Address *"
                            onChange={e => updatePostData('affectedEmailAddress', e.target.value)}
                            value={postData.affectedEmailAddress || ''}
                            error={validations.affectedEmailAddress || null}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 30 }}>
                          <TextField
                            className="create-ticket-textarea"
                            multiline
                            label="Description *"
                            onChange={e => updatePostData('description', e.target.value)}
                            value={postData.description || ''}
                            variant="outlined"
                            minRows={4}
                            error={validations.description || null}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {files.length === 0 && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid style={{ marginBottom: 10 }}>
                            <Button
                              disabled={updateTicketLoading}
                              noArrow
                              borderOnly
                              additionalClassName="visually-hidden-input">
                              <label htmlFor="attachment">
                                <UploadIcon /> Error Message/Screenshot (jpg, png)
                              </label>
                              <input
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                id="attachment"
                                type="file"
                                // multiple
                                onChange={handleSelectFiles}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {files.length > 0 && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid style={{ marginBottom: 10 }}>
                            <h4>Selected file</h4>
                            <div className="file-list">
                              {files.map((file, fileIndex) => (
                                <div className="file-item" key={`${fileIndex}-${file.name}-${file.size}`}>
                                  {file.name} ({filesize(file.size)})
                                  <button onClick={() => removeFile(fileIndex)} disabled={updateTicketLoading}>
                                    <DeleteIcon />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {postData.component && postData.component !== 'VDO Fleet App' && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Vehicle Registration Number"
                                onChange={e => updatePostData('vehicleRegistrationNumber', e.target.value)}
                                value={postData.vehicleRegistrationNumber || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label={`VDO Fleet Account - Affected Driver (Name)${
                                  postData.typeOfProblem === 'VDO Fleet' ? ` *` : ``
                                }`}
                                onChange={e => updatePostData('affectedDriver', e.target.value)}
                                value={postData.affectedDriver || ''}
                                error={validations.affectedDriver || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Company Card Number"
                                onChange={e => updatePostData('companyCardNumber', e.target.value)}
                                value={postData.companyCardNumber || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label={`Device Serial Number${postData.typeOfProblem === 'Hardware' ? ` *` : ``}`}
                                onChange={e => updatePostData('deviceserialnumber', e.target.value)}
                                value={postData.deviceserialnumber || ''}
                                error={validations.deviceserialnumber || null}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {postData.typeOfProblem !== null || (postData.requestType && postData.requestType !== 'service') ? (
                  <div className="create-ticket-btns">
                    {postData.requestType && postData.requestType === 'service' && (
                      <>
                        <button
                          disabled={updateTicketLoading}
                          className="btn-restart"
                          onClick={() => {
                            setPostData(initPostData);
                            setValidations({});
                          }}>
                          {t('Tickets.restartProcess')}
                        </button>
                        <button
                          disabled={updateTicketLoading}
                          onClick={() => {
                            setPostData({
                              ...postData,
                              typeOfProblem: null,
                              component: null,
                              affectedDriver: null,
                              deviceserialnumber: null,
                              companyCardNumber: null,
                              vehicleRegistrationNumber: null,
                              affectedVehicle: null,
                              attachment: null,
                              attachmentFileName: null
                            });
                            setValidations({});
                          }}
                          className="btn-border btn-prev">
                          {t('Tickets.back')}
                        </button>
                      </>
                    )}
                    <Button
                      disabled={updateTicketLoading}
                      borderOnly
                      onClick={handleSubmit}
                      additionalClassName="btn-submit">
                      {updateTicketLoading ? t('personalData.loading') : t('Tickets.submit')}
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <CancelCreateTicketModal
        onClose={() => setCancelModalOpen(false)}
        open={cancelModalOpen}
        closeNewTicket={closeNewTicket}
      />
    </>
  );
}
