/** @format */

export const marketMap = new Map();
marketMap.set('at', 'Austria');
marketMap.set('de', 'Germany');
marketMap.set('en', 'English');
marketMap.set('be', 'Belgium');
marketMap.set('it', 'Italy');
marketMap.set('fr', 'France');
marketMap.set('gb', 'United Kingdom');
marketMap.set('es', 'Spain');
marketMap.set('nl', 'Netherlands');
marketMap.set('bg', 'Bulgarian');
marketMap.set('cs', 'Czech');
marketMap.set('sk', 'Slovak');
marketMap.set('hu', 'Hungarian');
marketMap.set('sr', 'Serbian');
marketMap.set('hr', 'Croatian');
marketMap.set('bs', 'Bosnia');
marketMap.set('da', 'Denmark');
marketMap.set('et', 'Estonia');
marketMap.set('fi', 'Finland');
marketMap.set('el', 'Greece');
marketMap.set('is', 'Iceland');
marketMap.set('lv', 'Latvia');
marketMap.set('lt', 'Lithuania');
marketMap.set('no', 'Norway');
marketMap.set('pt', 'Portugal');
marketMap.set('br', 'Brazil');
marketMap.set('ro', 'Romania');
marketMap.set('ru', 'Russia');
marketMap.set('sl', 'Slovenia');
marketMap.set('sv', 'Sweden');
marketMap.set('tr', 'Turkey');
marketMap.set('uk', 'Ukraine');

export const countryOptions = [
  { value: 'af', name: 'Afghanistan' },
  { value: 'al', name: 'Albania' },
  { value: 'dz', name: 'Algeria' },
  { value: 'as', name: 'American Samoa' },
  { value: 'ad', name: 'Andorra' },
  { value: 'ao', name: 'Angola' },
  { value: 'ai', name: 'Anguilla' },
  { value: 'aq', name: 'Antarctica' },
  { value: 'ag', name: 'Antigua and Barbuda' },
  { value: 'ar', name: 'Argentina' },
  { value: 'am', name: 'Armenia' },
  { value: 'aw', name: 'Aruba' },
  { value: 'au', name: 'Australia' },
  { value: 'at', name: 'Austria' },
  { value: 'az', name: 'Azerbaijan' },
  { value: 'bs', name: 'Bahamas' },
  { value: 'bh', name: 'Bahrain' },
  { value: 'bd', name: 'Bangladesh' },
  { value: 'bb', name: 'Barbados' },
  { value: 'by', name: 'Belarus' },
  { value: 'be', name: 'Belgium' },
  { value: 'bz', name: 'Belize' },
  { value: 'bj', name: 'Benin' },
  { value: 'bm', name: 'Bermuda' },
  { value: 'bt', name: 'Bhutan' },
  { value: 'bo', name: 'Bolivia' },
  { value: 'ba', name: 'Bosnia and Herzegovina' },
  { value: 'bw', name: 'Botswana' },
  { value: 'br', name: 'Brazil' },
  { value: 'bn', name: 'Brunei Darussalam' },
  { value: 'bg', name: 'Bulgaria' },
  { value: 'bf', name: 'Burkina Faso' },
  { value: 'bi', name: 'Burundi' },
  { value: 'kh', name: 'Cambodia' },
  { value: 'cm', name: 'Cameroon' },
  { value: 'ca', name: 'Canada' },
  { value: 'cv', name: 'Cape Verde' },
  { value: 'ky', name: 'Cayman Islands' },
  { value: 'cf', name: 'Central African Republic' },
  { value: 'td', name: 'Chad' },
  { value: 'cl', name: 'Chile' },
  { value: 'cn', name: 'China' },
  { value: 'cx', name: 'Christmas Island' },
  { value: 'cc', name: 'Cocos (Keeling) Islands' },
  { value: 'co', name: 'Colombia' },
  { value: 'km', name: 'Comoros' },
  { value: 'cg', name: 'Congo, Republic of (Brazzaville)' },
  { value: 'ck', name: 'Cook Islands' },
  { value: 'cr', name: 'Costa Rica' },
  { value: 'ci', name: "Côte D'ivoire (Ivory Coast)" },
  { value: 'hr', name: 'Croatia' },
  { value: 'cu', name: 'Cuba' },
  { value: 'cy', name: 'Cyprus' },
  { value: 'cz', name: 'Czech Republic' },
  { value: 'cd', name: 'Democratic Republic of the Congo (Kinshasa)' },
  { value: 'dk', name: 'Denmark' },
  { value: 'dj', name: 'Djibouti' },
  { value: 'dm', name: 'Dominica' },
  { value: 'do', name: 'Dominican Republic' },
  { value: 'tl', name: 'East Timor (Timor-Leste)' },
  { value: 'ec', name: 'Ecuador' },
  { value: 'eg', name: 'Egypt' },
  { value: 'sv', name: 'El Salvador' },
  { value: 'gq', name: 'Equatorial Guinea' },
  { value: 'er', name: 'Eritrea' },
  { value: 'ee', name: 'Estonia' },
  { value: 'et', name: 'Ethiopia' },
  { value: 'fk', name: 'Falkland Islands' },
  { value: 'fo', name: 'Faroe Islands' },
  { value: 'fj', name: 'Fiji' },
  { value: 'fi', name: 'Finland' },
  { value: 'fr', name: 'France' },
  { value: 'gf', name: 'French Guiana' },
  { value: 'pf', name: 'French Polynesia' },
  { value: 'tf', name: 'French Southern Territories' },
  { value: 'ga', name: 'Gabon' },
  { value: 'ge', name: 'Georgia' },
  { value: 'de', name: 'Germany' },
  { value: 'gh', name: 'Ghana' },
  { value: 'gi', name: 'Gibraltar' },
  { value: 'gr', name: 'Greece' },
  { value: 'gl', name: 'Greenland' },
  { value: 'gd', name: 'Grenada' },
  { value: 'gp', name: 'Guadeloupe' },
  { value: 'gu', name: 'Guam' },
  { value: 'gt', name: 'Guatemala' },
  { value: 'gn', name: 'Guinea' },
  { value: 'gw', name: 'Guinea-Bissau' },
  { value: 'gy', name: 'Guyana' },
  { value: 'ht', name: 'Haiti' },
  { value: 'hn', name: 'Honduras' },
  { value: 'hk', name: 'Hong Kong' },
  { value: 'hu', name: 'Hungary' },
  { value: 'is', name: 'Iceland' },
  { value: 'in', name: 'India' },
  { value: 'id', name: 'Indonesia' },
  { value: 'ir', name: 'Iran (Islamic Republic of)' },
  { value: 'iq', name: 'Iraq' },
  { value: 'ie', name: 'Ireland' },
  { value: 'il', name: 'Israel' },
  { value: 'it', name: 'Italy' },
  { value: 'jm', name: 'Jamaica' },
  { value: 'jp', name: 'Japan' },
  { value: 'jo', name: 'Jordan' },
  { value: 'kz', name: 'Kazakhstan' },
  { value: 'ke', name: 'Kenya' },
  { value: 'ki', name: 'Kiribati' },
  { value: 'kp', name: "Korea, Democratic People's Rep. (North Korea)" },
  { value: 'kr', name: 'Korea, Republic of (South Korea)' },
  { value: 'xk', name: 'Kosovo' },
  { value: 'kw', name: 'Kuwait' },
  { value: 'kg', name: 'Kyrgyzstan' },
  { value: 'la', name: "Lao, People's Democratic Republic" },
  { value: 'lv', name: 'Latvia' },
  { value: 'lb', name: 'Lebanon' },
  { value: 'ls', name: 'Lesotho' },
  { value: 'lr', name: 'Liberia' },
  { value: 'ly', name: 'Libya' },
  { value: 'li', name: 'Liechtenstein' },
  { value: 'lt', name: 'Lithuania' },
  { value: 'lu', name: 'Luxembourg' },
  { value: 'mo', name: 'Macau' },
  { value: 'mg', name: 'Madagascar' },
  { value: 'mw', name: 'Malawi' },
  { value: 'my', name: 'Malaysia' },
  { value: 'mv', name: 'Maldives' },
  { value: 'ml', name: 'Mali' },
  { value: 'mt', name: 'Malta' },
  { value: 'mh', name: 'Marshall Islands' },
  { value: 'mq', name: 'Martinique' },
  { value: 'mr', name: 'Mauritania' },
  { value: 'mu', name: 'Mauritius' },
  { value: 'yt', name: 'Mayotte' },
  { value: 'mx', name: 'Mexico' },
  { value: 'fm', name: 'Micronesia, Federal States of' },
  { value: 'md', name: 'Moldova, Republic of' },
  { value: 'mc', name: 'Monaco' },
  { value: 'mn', name: 'Mongolia' },
  { value: 'me', name: 'Montenegro' },
  { value: 'ms', name: 'Montserrat' },
  { value: 'ma', name: 'Morocco' },
  { value: 'mz', name: 'Mozambique' },
  { value: 'mm', name: 'Myanmar, Burma' },
  { value: 'na', name: 'Namibia' },
  { value: 'nr', name: 'Nauru' },
  { value: 'np', name: 'Nepal' },
  { value: 'nl', name: 'Netherlands' },
  { value: 'an', name: 'Netherlands Antilles' },
  { value: 'nc', name: 'New Caledonia' },
  { value: 'nz', name: 'New Zealand' },
  { value: 'ni', name: 'Nicaragua' },
  { value: 'ne', name: 'Niger' },
  { value: 'ng', name: 'Nigeria' },
  { value: 'nu', name: 'Niue' },
  { value: 'mk', name: 'North Macedonia' },
  { value: 'mp', name: 'Northern Mariana Islands' },
  { value: 'no', name: 'Norway' },
  { value: 'om', name: 'Oman' },
  { value: 'pk', name: 'Pakistan' },
  { value: 'pw', name: 'Palau' },
  { value: 'ps', name: 'Palestinian territories' },
  { value: 'pa', name: 'Panama' },
  { value: 'pg', name: 'Papua New Guinea' },
  { value: 'py', name: 'Paraguay' },
  { value: 'pe', name: 'Peru' },
  { value: 'ph', name: 'Philippines' },
  { value: 'pn', name: 'Pitcairn Island' },
  { value: 'pl', name: 'Poland' },
  { value: 'pt', name: 'Portugal' },
  { value: 'pr', name: 'Puerto Rico' },
  { value: 'qa', name: 'Qatar' },
  { value: 're', name: 'Reunion Island' },
  { value: 'ro', name: 'Romania' },
  { value: 'ru', name: 'Russian Federation' },
  { value: 'rw', name: 'Rwanda' },
  { value: 'kn', name: 'Saint Kitts and Nevis' },
  { value: 'lc', name: 'Saint Lucia' },
  { value: 'vc', name: 'Saint Vincent and the Grenadines' },
  { value: 'ws', name: 'Samoa' },
  { value: 'sm', name: 'San Marino' },
  { value: 'st', name: 'Sao Tome and Principe' },
  { value: 'sa', name: 'Saudi Arabia' },
  { value: 'sn', name: 'Senegal' },
  { value: 'rs', name: 'Serbia' },
  { value: 'sc', name: 'Seychelles' },
  { value: 'sl', name: 'Sierra Leone' },
  { value: 'sg', name: 'Singapore' },
  { value: 'sk', name: 'Slovakia (Slovak Republic)' },
  { value: 'si', name: 'Slovenia' },
  { value: 'sb', name: 'Solomon Islands' },
  { value: 'so', name: 'Somalia' },
  { value: 'za', name: 'South Africa' },
  { value: 'ss', name: 'South Sudan' },
  { value: 'es', name: 'Spain' },
  { value: 'lk', name: 'Sri Lanka' },
  { value: 'sd', name: 'Sudan' },
  { value: 'sr', name: 'Suriname' },
  { value: 'sz', name: 'Swaziland (Eswatini)' },
  { value: 'se', name: 'Sweden' },
  { value: 'ch', name: 'Switzerland' },
  { value: 'sy', name: 'Syria, Syrian Arab Republic' },
  { value: 'tw', name: 'Taiwan (Republic of China)' },
  { value: 'tj', name: 'Tajikistan' },
  { value: 'tz', name: 'Tanzania (United Republic of Tanzania)' },
  { value: 'th', name: 'Thailand' },
  { value: 'gm', name: 'The Gambia' },
  { value: 'tg', name: 'Togo' },
  { value: 'tk', name: 'Tokelau' },
  { value: 'to', name: 'Tonga' },
  { value: 'tt', name: 'Trinidad and Tobago' },
  { value: 'tn', name: 'Tunisia' },
  { value: 'tr', name: 'Türkiye' },
  { value: 'tm', name: 'Turkmenistan' },
  { value: 'tc', name: 'Turks and Caicos Islands' },
  { value: 'tv', name: 'Tuvalu' },
  { value: 'ug', name: 'Uganda' },
  { value: 'ua', name: 'Ukraine' },
  { value: 'ae', name: 'United Arab Emirates' },
  { value: 'gb', name: 'United Kingdom' },
  { value: 'us', name: 'United States' },
  { value: 'uy', name: 'Uruguay' },
  { value: 'uz', name: 'Uzbekistan' },
  { value: 'vu', name: 'Vanuatu' },
  { value: 'va', name: 'Vatican City State (Holy See)' },
  { value: 've', name: 'Venezuela' },
  { value: 'vn', name: 'Vietnam' },
  { value: 'vg', name: 'Virgin Islands (British)' },
  { value: 'vi', name: 'Virgin Islands (U.S.)' },
  { value: 'wf', name: 'Wallis and Futuna Islands' },
  { value: 'eh', name: 'Western Sahara' },
  { value: 'ye', name: 'Yemen' },
  { value: 'zm', name: 'Zambia' },
  { value: 'zw', name: 'Zimbabwe' }
];

export const countriesMap = new Map();
for (let key = 0; key < countryOptions.length; key++) {
  const { value, name } = countryOptions[key];
  countriesMap.set(value, name);
}
