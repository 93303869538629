/** @format */

import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Button } from '../button';
import SelectComponent from '../select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'src/store';
import { setUserData, updateUserInfo } from 'src/slices/users';
import { scenarioRequiredFields } from 'src/views/survey';
import { fixSalutationValues } from 'src/utils/helper';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    '& > *': {
      fontSize: 18,
      color: '#333'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        borderBottom: '2px solid black'
      },
      '&:after': {
        borderBottom: '2px solid black'
      }
    },
    '& > .MuiInputLabel-formControl': {
      color: 'black'
    }
  }
}));

export default function SurveyModalStep2({ setStep }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const { userData } = useSelector(state => state.users);
  const [isValidated, setIsValidated] = useState(false);

  const scenarioOptions = [
    { value: 'driver', name: t('personalData.scenario.driver') },
    { value: 'fleet', name: t('personalData.scenario.fleet') },
    { value: 'workshop-cv', name: t('personalData.scenario.workshop-cv') },
    { value: 'workshop-pv', name: t('personalData.scenario.workshop-pv') },
    { value: 'partner', name: t('personalData.scenario.partner') },
    { value: 'authority', name: t('personalData.scenario.authority') },
    { value: 'trainer', name: t('personalData.scenario.trainer') },
    { value: 'conti-internal', name: t('personalData.scenario.conti-internal') },
    { value: 'kaas-customer', name: t('personalData.scenario.kaas-customer') },
    {
      value: 'maps-on-demand-customer-nissan-isa',
      name: t('personalData.scenario.maps-on-demand-customer-nissan-isa')
    },
    {
      value: 'maps-on-demand-customer-nissan-tiny',
      name: t('personalData.scenario.maps-on-demand-customer-nissan-tiny')
    },
    {
      value: 'maps-on-demand-customer-mitsubishi',
      name: t('personalData.scenario.maps-on-demand-customer-mitsubishi')
    },
    { value: 'maps-on-demand-customer-renault', name: t('personalData.scenario.maps-on-demand-customer-renault') },
    { value: 'tolling-proxy-customer', name: t('personalData.scenario.tolling-proxy-customer') }
  ];
  const roleOptions = [
    { value: 'fleet manager', name: t('personalData.roles.fleet manager') },
    { value: 'dispatcher', name: t('personalData.roles.dispatcher') },
    { value: 'employed driver', name: t('personalData.roles.employed driver') },
    { value: 'driver trainer', name: t('personalData.roles.driver trainer') },
    { value: 'workshop-cv manager', name: t('personalData.roles.workshop manager') },
    { value: 'owner', name: t('personalData.roles.owner') },
    { value: 'mechanic/technician', name: t('personalData.roles.mechanic/technician') },
    { value: 'sales', name: t('personalData.roles.sales') },
    { value: 'support', name: t('personalData.roles.support') },
    { value: 'project/product manager', name: t('personalData.roles.project/product manager') },
    { value: 'logistics', name: t('personalData.roles.logistics') },
    { value: 'service & support', name: t('personalData.roles.service & support') },
    { value: 'accounting', name: t('personalData.roles.accounting') },
    { value: 'general management', name: t('personalData.roles.general management') },
    { value: 'legal', name: t('personalData.roles.legal') },
    { value: 'accm', name: t('personalData.roles.accm') },
    { value: 'fcs', name: t('personalData.roles.fcs') },
    { value: 'cosma', name: t('personalData.roles.cosma') },
    { value: 'tiny', name: t('personalData.roles.tiny') },
    { value: 'isa', name: t('personalData.roles.isa') },
    { value: 'other', name: t('personalData.roles.other') }
  ];
  const fleetSizeOptions = [
    { value: '1', name: t('homeSurvey.fleet.1') },
    { value: '2-5', name: t('homeSurvey.fleet.2-5') },
    { value: '6-10', name: t('homeSurvey.fleet.6-10') },
    { value: '11-20', name: t('homeSurvey.fleet.11-20') },
    { value: '21-50', name: t('homeSurvey.fleet.21-50') },
    { value: '50+', name: t('homeSurvey.fleet.50+') }
  ];
  const typeOfTransportOptions = [
    { value: 'national', name: t('homeSurvey.fleet.national') },
    { value: 'international', name: t('homeSurvey.fleet.international') },
    { value: 'both', name: t('homeSurvey.fleet.both') }
  ];
  const scenario = userData.scenario || '';

  useEffect(() => {
    setIsValidated(false);
    if (
      [
        'driver',
        'authority',
        'tolling-proxy-customer',
        'maps-on-demand-customer-nissan',
        'maps-on-demand-customer-renault'
      ].includes(scenario)
    ) {
      setIsValidated(true);
    }
    if (scenarioRequiredFields[scenario]?.includes('role') && userData.role && userData.role !== 'other') {
      setIsValidated(true);
    }
    if (userData.role && userData.role === 'other' && userData.roleOther) {
      setIsValidated(true);
    }
  }, [userData]);

  useEffect(() => {
    if (scenario) {
      switch (scenario) {
        case 'driver':
        case 'authority':
        case 'tolling-proxy-customer':
        case 'maps-on-demand-customer-nissan':
        case 'maps-on-demand-customer-renault':
          setRoles([]);
          break;
        case 'fleet':
          setRoles(
            [...roleOptions].filter(role =>
              [
                'fleet manager',
                'dispatcher',
                'employed driver',
                'driver trainer',
                'workshop-cv manager',
                'owner',
                'other'
              ].includes(role.value)
            )
          );
          break;
        case 'workshop-cv':
        case 'workshop-pv':
          setRoles(
            [...roleOptions].filter(role =>
              ['workshop-cv manager', 'owner', 'mechanic/technician', 'other'].includes(role.value)
            )
          );
          break;
        case 'partner':
          setRoles([...roleOptions].filter(role => ['owner', 'sales', 'support', 'other'].includes(role.value)));
          break;
        case 'trainer':
          setRoles([...roleOptions].filter(role => ['fleet manager', 'driver trainer', 'other'].includes(role.value)));
          break;
        case 'conti-internal':
          setRoles(
            [...roleOptions].filter(role =>
              [
                'sales',
                'project/product manager',
                'logistics',
                'service & support',
                'accounting',
                'general management',
                'legal',
                'other'
              ].includes(role.value)
            )
          );
          break;
        case 'kaas-customer':
          setRoles([...roleOptions].filter(role => ['accm', 'fcs', 'cosma'].includes(role.value)));
          break;
        default:
          setRoles([...roleOptions]);
      }
    }
    // eslint-disable-next-line
  }, [scenario]);

  const handleNextStep = async () => {
    setStep(3);
  };

  return (
    <Grid className="survey-step survey-step2">
      <Typography className="survey-step-step">{t('homeSurvey.step2Step')}</Typography>
      <Typography className="survey-step-title">
        {t('homeSurvey.step2Title', {
          welcome: `${t(fixSalutationValues[userData.salutation])} ${userData.firstName}`
        })}
      </Typography>
      <Typography className="survey-step-content">{t('homeSurvey.step2Content')}</Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <SelectComponent
            label={t('homeSurvey.step2SelectGroup')}
            options={scenarioOptions}
            onChange={e => dispatch(setUserData({ ...userData, scenario: e.target.value }))}
            value={scenario}
          />
        </Grid>
        {roles.length > 0 && scenario !== '' && scenarioRequiredFields[scenario].includes('role') && (
          <Grid item xs={12} md={6}>
            <SelectComponent
              label={t('homeSurvey.step2SelectRole')}
              options={roles}
              onChange={e => dispatch(setUserData({ ...userData, role: e.target.value }))}
              value={userData.role}
            />
          </Grid>
        )}

        {userData.role === 'other' && (
          <Grid item xs={12}>
            <TextField
              label={t('homeSurvey.step2OtherRole')}
              className={classes.textField}
              onChange={e => dispatch(setUserData({ ...userData, roleOther: e.target.value }))}
              value={userData.roleOther}
            />
          </Grid>
        )}

        {scenario === 'fleet' && (
          <>
            <Grid item xs={12} md={6}>
              <SelectComponent
                label={t('homeSurvey.step2SelectFleetSize')}
                options={fleetSizeOptions}
                onChange={e => dispatch(setUserData({ ...userData, fleetSize: e.target.value }))}
                value={userData.fleetSize}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectComponent
                label={t('homeSurvey.step2SelectTypeOfTransport')}
                options={typeOfTransportOptions}
                onChange={e => dispatch(setUserData({ ...userData, transportationType: e.target.value }))}
                value={userData.transportationType}
              />
            </Grid>
          </>
        )}
        {(scenario === 'workshop-cv' || scenario === 'partner') && (
          <Grid item xs={12} md={6}>
            <TextField
              label={t('homeSurvey.step2FieldSealNumber')}
              className={classes.textField}
              onChange={e => dispatch(setUserData({ ...userData, sealNumber: e.target.value }))}
              value={userData.sealNumber}
            />
          </Grid>
        )}
        {(scenario === 'workshop-pv' || scenario === 'partner') && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('homeSurvey.step2FieldDRS')}
                className={classes.textField}
                onChange={e => dispatch(setUserData({ ...userData, drsId: e.target.value }))}
                value={userData.drsId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('homeSurvey.step2FieldATE')}
                className={classes.textField}
                onChange={e => dispatch(setUserData({ ...userData, brakeCenterId: e.target.value }))}
                value={userData.brakeCenterId}
              />
            </Grid>
          </>
        )}
        {scenario === 'partner' && (
          <Grid item xs={12} md={6}>
            <TextField
              label={t('homeSurvey.step2FieldVDOFleetDealer')}
              className={classes.textField}
              onChange={e => dispatch(setUserData({ ...userData, dealerId: e.target.value }))}
              value={userData.dealerId}
            />
          </Grid>
        )}
        {scenario === 'conti-internal' && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('homeSurvey.step2FieldLocationRSO')}
                className={classes.textField}
                onChange={e => dispatch(setUserData({ ...userData, locationRso: e.target.value }))}
                value={userData.locationRso}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectComponent
                label={t('homeSurvey.step2FieldSegment')}
                options={[
                  { value: 's1', name: 'S1' },
                  { value: 's2', name: 'S2' },
                  { value: 's3', name: 'S3' },
                  { value: 's4', name: 'S4' },
                  { value: 's5', name: 'S5' }
                ]}
                onChange={e => dispatch(setUserData({ ...userData, segment: e.target.value }))}
                value={userData.segment}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
        <Button
          onClick={() => {
            setStep(1);
          }}
          label={<label>{t('Tickets.back')}</label>}
          additionalClassName="survey-step-btn survey-step-btn--back step-2"
          noArrow
          borderOnly
          secondary
        />
        <Button
          disabled={!isValidated}
          onClick={handleNextStep}
          label={<label>{t('homeSurvey.step2Btn')}</label>}
          additionalClassName="survey-step-btn"
          borderOnly
          secondary
        />
      </Grid>
    </Grid>
  );
}
