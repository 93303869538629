/** @format */

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';

export default function SurveyModalStep5({ setStep }) {
  const { t } = useTranslation();
  return (
    <Grid className="survey-step survey-step4">
      <img className="survey-step-img" src="/static/home/survey_1.png" alt="" width={296} height={164} />
      <Typography className="survey-step-title">{t('homeSurvey.step4Title')}</Typography>
      <Typography className="survey-step-content">{t('homeSurvey.step4Content')}</Typography>
      <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 64 }}>
        <Button
          onClick={() => setStep(0)}
          borderOnly
          secondary
          noArrow
          label={<label>{t('homeSurvey.step4Btn')}</label>}
        />
      </Grid>
    </Grid>
  );
}
