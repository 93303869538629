/** @format */

import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { WelcomeTitle, WidgetGrid } from '../../components';
// import { Tutorials } from 'src/views/content/tutorials';
import useAuth from 'src/hooks/useAuth';

import Can from 'src/components/Can';

import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import SurveyModal from 'src/components/surveyModal';
import { useDispatch, useSelector } from 'src/store';
import { setUserData } from 'src/slices/users';
import { missingRequiredFieldsChecking } from '../survey';

const HomeView = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSurvey, setShowSurvey] = useState(false);

  const { userData } = useSelector(state => state.users);

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && ((userData && Object.keys(userData).length === 0) || !userData)) {
      dispatch(setUserData({ ...user }));
    }
  }, [user, dispatch]);

  const breadcrumbData = [
    {
      title: t('dashboard')
    }
  ];

  // useEffect(() => {
  //   if (user && Object.keys(user).length > 0) {
  //     if (missingRequiredFieldsChecking(user)) setShowSurvey(true);
  //   }
  // }, [user]);

  return (
    <Page className={`dashboard`} title="Dashboard">
      <Breadcrumb data={breadcrumbData} />
      <WelcomeTitle>
        {user && user.firstName && t('welcome')} {user && user.firstName} {user && user.lastName}
        {user && user.firstName && '!'}
      </WelcomeTitle>
      {/* Rendering UI based on role check */}
      <Can
        role={'admin'}
        perform="Dashboard: read"
        yes={() => {
          return <WidgetGrid />;
        }}
      />
      {showSurvey && <SurveyModal />}
    </Page>
  );
};

export default HomeView;
