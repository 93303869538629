/** @format */

const getRedirectionLink = item => {
  if (item?.nid) {
    if (item.public) {
      return `/page/public/${item?.nid}`;
    }
    return `/page/${item?.nid}`;
  }
  if (item.link) {
    return item.link;
  }
  return '/404';
};

export const getFileBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Str = reader.result;
      resolve(base64Str.split(';base64,').length > 1 ? base64Str.split(';base64,')[1] : null);
    };
    reader.onerror = reject;
  });
};

export const fixSalutationValues = {
  mr: 'mr',
  mrs: 'mrs',
  Frau: 'mrs',
  Herr: 'mr',
  Monsieur: 'mr',
  other: 'others'
};

export default getRedirectionLink;
