/** @format */

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Backbtn } from '../../components/button/backBtn';
import { withStyles } from '@material-ui/core/styles';
import InnerHTML from 'dangerously-set-html-content';
import { filesize } from 'filesize';
import DownloadIcon from '../../assets/imgs/download.png';
import { Grid, Switch } from '@material-ui/core';
// import 'antd/dist/antd.css';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

const ContentBlock = props => {
  const { content } = props;
  const { t } = useTranslation();
  const [cookieContent, setCookieContent] = useState(null);
  const { pageId, type } = useParams();

  // debugger
  useEffect(() => {
    let VDO_cookieSettings = getCookie('VDO_cookieSettings');
    if (VDO_cookieSettings) {
      let removeBr1 = VDO_cookieSettings.replace('{', '');
      let removeBr2 = removeBr1.replace('}', '');
      let value = removeBr2.split(':');
      let trueFalse = JSON.parse(value[1]);
      setCookieContent(trueFalse);
    }
  }, []);

  const getCookie = cname => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  useEffect(() => {
    let cookiePage = content.title;
    if (cookiePage === 'Cookies') {
      const element = <Switch onChange={onChange} defaultChecked={cookieContent} />;
      ReactDOM.render(element, document.getElementById('switch_btn'));
    }
    // eslint-disable-next-line
  }, [props, content]);

  function onChange(checked) {
    if (checked === false) {
      setCookie('VDO_cookieSettings', "{'Google_Analytics_cookieSwitch':false}", 30);
    } else {
      setCookie('VDO_cookieSettings', "{'Google_Analytics_cookieSwitch':true}", 30);
    }
  }

  const Downloads = ({ downloads }) => {
    const getDownloads = () => {
      return downloads.map((item, index) => (
        <Grid item key={index}>
          <a
            href={item.link}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="content-block-download-wrapper">
            <div className="download">
              <img className="image" src={DownloadIcon} alt="icon.png" />
              <div>
                <p>{item.linkText}</p>
                <p>{filesize(item.size)}</p>
              </div>
            </div>
          </a>
        </Grid>
      ));
    };
    return (
      <>
        <h2>Downloads</h2>
        <Grid container spacing={8}>
          {getDownloads()}
        </Grid>
      </>
    );
  };

  const cookieSwitchChangeHandler = event => {
    if (event.target.checked === false) {
      setCookie('VDO_cookieSettings', "{'Google_Analytics_cookieSwitch':false}", 30);
    } else {
      setCookie('VDO_cookieSettings', "{'Google_Analytics_cookieSwitch':true}", 30);
    }
    setCookieContent(event.target.checked);
    window.location.reload();
  };

  const cookieSwitchTemplate = () => {
    if (type === 'public' && pageId === '22') {
      return (
        <>
          <div>
            <h3>{t('cookiePage.optionalCookiesHeading')}</h3>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={10}>
                <p style={{ margin: '0' }}>
                  <strong>{t('cookiePage.googleAnalyticsTitle')}</strong>
                </p>
              </Grid>

              <Grid item xs={2}>
                <IOSSwitch checked={cookieContent} onChange={cookieSwitchChangeHandler} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <p>{t('cookiePage.googleAnalyticsText')}</p>
              </Grid>

              <Grid item xs={2}></Grid>
            </Grid>
          </div>
        </>
      );
    }
  };

  function setCookie(cname, cvalue, exMins) {
    var d = new Date();
    d.setTime(d.getTime() + exMins * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  return (
    <div className="content-container container">
      {/* <div className="content-title">
        <h2>{content.title || ''}</h2>
      </div> */}
      <div className="content-block">
        {content.body && <InnerHTML html={content.body} />}
        {/* {content.body && <p dangerouslySetInnerHTML={{ __html: content.body}}></p>} */}
        {content.iframe && (
          <iframe
            title="iframe"
            frameborder="0"
            style={{ width: '100%', height: '50vh', marginTop: 20, marginBottom: 20 }}
            src={content?.iframe}></iframe>
        )}
        {content.bodySecondary && <p dangerouslySetInnerHTML={{ __html: content?.bodySecondary }}></p>}

        {content.downloads && content.downloads.length && <Downloads downloads={content.downloads} />}

        {cookieSwitchTemplate()}
      </div>
      <Backbtn />
    </div>
  );
};

export { ContentBlock };
