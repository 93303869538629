/** @format */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import Ticket from 'src/components/widget/tickets/ticket';
import { TablePagination } from '@material-ui/core';
import { ENDPOINTS, TICKET_MODAL, TICKET_STATUS } from 'src/constants';
import axiosInstance from 'src/utils/axios';
import { useDispatch, useSelector } from 'src/store';
import { getTickets } from 'src/slices/tickets';
import UpdateTicketModal from 'src/components/widget/tickets/updateTicketModal';
import LoadingScreen from 'src/components/LoadingScreen';
import CloseTicketModal from 'src/components/widget/tickets/closeTicketModal';

// const ticketStatus = ['in_progress', 'closed', 'on_hold', 'canceled', 'submitted'];

export default function Tickets() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { page } = useParams();
  const [filter, setFilter] = React.useState('all');
  const [pagination, setPagination] = React.useState({ count: 0, page: 0, rowsPerPage: 5 });
  const { tickets, ticketLoading, error } = useSelector(state => state.tickets);
  const [currentUpdateTicketId, setCurrentUpdateTicketId] = React.useState(null);
  const [modalOpened, setModalOpened] = React.useState(null);

  React.useEffect(() => {
    if (tickets === null) {
      const Storage = window.localStorage;
      const lang = Storage.i18nextLng || 'en';
      dispatch(getTickets(lang));
    }
  }, []);

  React.useEffect(() => {
    if (tickets && tickets.length > 0) {
      const count = tickets.filter(ticket => {
        if (filter === 'all') return true;
        return ticket.status === filter;
      }).length;
      if (page && page > 0) setPagination(prev => ({ ...prev, count, page: page - 1 }));
      else setPagination(prev => ({ ...prev, count, page: 0 }));
    }
  }, [page, filter, tickets]);

  const toggleUpdateTicketModal = incidentid => {
    if (modalOpened === TICKET_MODAL.UPDATE_TICKET) {
      setModalOpened(null);
      setCurrentUpdateTicketId(null);
    } else {
      setModalOpened(TICKET_MODAL.UPDATE_TICKET);
      setCurrentUpdateTicketId(incidentid);
    }
  };
  const toggleCancelTicketModal = incidentid => {
    if (modalOpened === TICKET_MODAL.CLOSE_TICKET) {
      setModalOpened(null);
      setCurrentUpdateTicketId(null);
    } else {
      setModalOpened(TICKET_MODAL.CLOSE_TICKET);
      setCurrentUpdateTicketId(incidentid);
    }
  };
  const handleFilter = _filter => {
    setFilter(_filter);
  };
  const handleBack = () => {
    if (typeof location.key !== 'undefined') history.goBack();
    else history.push(`/tickets`);
  };

  const start = pagination.page * pagination.rowsPerPage;
  const end = pagination.page * pagination.rowsPerPage + pagination.rowsPerPage;

  return (
    <Page className={`tickets`} title="Tickets">
      <div className="container">
        <button onClick={handleBack} className="btn-back">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.93534 12.7715C5.45038 12.3715 5.45038 11.6285 5.93534 11.2285L13.8637 4.68933C14.516 4.15137 15.5 4.61532 15.5 5.46078L15.5 18.5392C15.5 19.3847 14.516 19.8486 13.8637 19.3107L5.93534 12.7715Z"
              fill="#234D96"
            />
          </svg>
          <span>{t('Tickets.back')}</span>
        </button>
        <h1 className="tickets-title">{t('Tickets.yourSuportServices')}</h1>
        <nav className="ticket-filter">
          <ul>
            <li className={filter === 'all' ? 'active' : ''}>
              <button onClick={() => handleFilter('all')}>{t('Tickets.all')}</button>
            </li>
            <li className={filter === TICKET_STATUS.new ? 'active' : ''}>
              <button onClick={() => handleFilter(TICKET_STATUS.new)}>{t('Tickets.new')}</button>
            </li>
            <li className={filter === TICKET_STATUS['in progress'] ? 'active' : ''}>
              <button onClick={() => handleFilter(TICKET_STATUS['in progress'])}>{t('Tickets.inProgress')}</button>
            </li>
            <li className={filter === TICKET_STATUS.pending ? 'active' : ''}>
              <button onClick={() => handleFilter(TICKET_STATUS.pending)}>{t('Tickets.pending')}</button>
            </li>
            <li className={filter === TICKET_STATUS.resolved ? 'active' : ''}>
              <button onClick={() => handleFilter(TICKET_STATUS.resolved)}>{t('Tickets.resolved')}</button>
            </li>
            <li className={filter === TICKET_STATUS.cancelled ? 'active' : ''}>
              <button onClick={() => handleFilter(TICKET_STATUS.cancelled)}>{t('Tickets.cancelled')}</button>
            </li>
            <li className={filter === TICKET_STATUS.closed ? 'active' : ''}>
              <button onClick={() => handleFilter(TICKET_STATUS.closed)}>{t('Tickets.closed')}</button>
            </li>
          </ul>
        </nav>
        {tickets ? (
          <ol className="tickets-table">
            <li className="ticket ticket-container in-page">
              <div className="attribute">{t('Tickets.caseID')}</div>
              <div className="attribute">{t('Tickets.title')}</div>
              <div className="attribute">{t('Tickets.description')}</div>
              <div className="attribute">{t('Tickets.submitted')}</div>
              <div className="attribute">{t('Tickets.status')}</div>
              <div className="attribute"></div>
            </li>
            {tickets
              .filter(ticket => {
                if (filter === 'all') return true;
                if (!ticket.status && filter === TICKET_STATUS.new) return true;
                return ticket.status === filter;
              })
              .sort((a, b) => new Date(b.createdon) - new Date(a.createdon))
              .slice(start, end)
              .map(ticket => (
                <Ticket
                  key={ticket.ticketnumber}
                  inPage
                  ticket={ticket}
                  toggleUpdateTicketModal={toggleUpdateTicketModal}
                  toggleCancelTicketModal={toggleCancelTicketModal}
                />
              ))}
          </ol>
        ) : (
          <LoadingScreen />
        )}

        <div className="table-pagination">
          <TablePagination
            component="div"
            count={pagination.count}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onPageChange={(e, page) => {
              setPagination(prev => ({ ...prev, page }));
              // history.push(`/tickets/page/${page + 1}`);
            }}
            onRowsPerPageChange={e => {
              setPagination(prev => ({ ...prev, rowsPerPage: e.target.value }));
            }}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </div>
      </div>
      <UpdateTicketModal
        incidentid={currentUpdateTicketId}
        open={modalOpened === TICKET_MODAL.UPDATE_TICKET}
        onClose={() => {
          setModalOpened(null);
          toggleUpdateTicketModal(null);
        }}
      />
      <CloseTicketModal
        open={modalOpened === TICKET_MODAL.CLOSE_TICKET}
        onClose={() => {
          setModalOpened(null);
          toggleCancelTicketModal(null);
        }}
        incidentid={currentUpdateTicketId}
      />
    </Page>
  );
}
