/** @format */

import * as React from 'react';
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TICKET_STATUS } from 'src/constants';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
  menuItemText: {
    color: 'rgba(35, 77, 150, 1)',
    marginRight: '8px',
    fontRamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textDecoration: 'underlined'
  },
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff'
    }
  }
}));

const StatusBadge = ({ status }) => {
  const { t } = useTranslation();
  switch (TICKET_STATUS[status]) {
    case TICKET_STATUS['in progress']:
      return <span className={`status-badge status-badge--in_progress`}>{t('Tickets.inProgress')}</span>;
    case TICKET_STATUS.closed:
      return <span className={`status-badge status-badge--closed`}>{t('Tickets.closed')}</span>;
    case TICKET_STATUS.pending:
      return <span className={`status-badge status-badge--on_hold`}>{t('Tickets.pending')}</span>;
    case TICKET_STATUS.resolved:
      return <span className={`status-badge status-badge--canceled`}>{t('Tickets.resolved')}</span>;
    case TICKET_STATUS.new:
      return <span className={`status-badge status-badge--submitted`}>{t('Tickets.new')}</span>;
    case TICKET_STATUS.cancelled:
      return <span className={`status-badge status-badge--canceled`}>{t('Tickets.cancelled')}</span>;
    default:
      return <span className={`status-badge status-badge--submitted`}>{t('Tickets.new')}</span>;
  }
};

export default function Ticket({ ticket, inPage, toggleUpdateTicketModal, toggleCancelTicketModal }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <li className={inPage ? 'in-page' : ''}>
      <div className="attribute" data-name={t('Tickets.caseID')}>
        <Link to={`/tickets/${ticket.incidentid}`} className="ticket-link">
          {ticket.ticketnumber}
        </Link>
      </div>
      {inPage ? (
        <>
          <div className="attribute" data-name={t('Tickets.title')}>
            {ticket.title}
          </div>
          <div className="attribute" data-name={t('Tickets.description')}>
            {ticket.description}
          </div>
        </>
      ) : (
        <div className="attribute" data-name={t('Tickets.title')}>
          {ticket.title}
        </div>
      )}
      <div className="attribute" data-name={t('Tickets.submitted')}>
        {format(new Date(ticket.createdon), 'dd.MM.yyyy')}
      </div>
      <div className="attribute" data-name={t('Tickets.status')}>
        <StatusBadge status={ticket.status} />
      </div>
      <div className="attribute">
        {TICKET_STATUS.closed !== ticket.status && (
          <>
            <Button
              className="ticket-options"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                <path
                  d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z"
                  fill="#868686"
                />
              </svg>
            </Button>
            <Menu
              id="basic-menu"
              className={classes.menu}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <MenuItem
                className={classes.menuItemText}
                onClick={() => {
                  toggleUpdateTicketModal(ticket.incidentid);
                  handleClose();
                }}>
                {t('Tickets.update')}
              </MenuItem>
              <MenuItem
                className={classes.menuItemText}
                onClick={() => {
                  toggleCancelTicketModal(ticket.incidentid);
                  handleClose();
                }}>
                {t('Tickets.cancel')}
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </li>
  );
}
