/** @format */

import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'src/store';
import { updateUserInfo } from 'src/slices/users';

export default function SurveyModalStep4({ setStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);
  const [check, setCheck] = useState(false);
  const [disabled, setDisabled] = useState(true);

  return (
    <Grid className="survey-step survey-step4">
      {/* <img className="survey-step-img" src="/static/home/survey_1.png" alt="" width={296} height={164} /> */}
      <Typography className="survey-step-title">{t('homeSurvey.stepPolicyTitle')}</Typography>
      <Grid
        style={{ display: 'flex', justifyContent: 'center', gap: 8, alignItems: 'center' }}
        className="survey-step-content">
        <input
          onChange={() => {
            setCheck(!check);
            setDisabled(check);
          }}
          id="confirm"
          type="checkbox"
          checked={check}
        />
        <label htmlFor="confirm">
          <a href="https://my.vdo.com/page/public/24" target="_blank" rel="noopener noreferrer nofollow" className="">
            {t('homeSurvey.stepPolicyText')}
          </a>
        </label>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 64, gap: 16 }}>
        <Button onClick={() => setStep(3)} borderOnly secondary noArrow label={<label>{t('Tickets.back')}</label>} />
        <Button
          onClick={async () => {
            setDisabled(true);
            await dispatch(updateUserInfo(userData));
            setDisabled(!check);
            setStep(5);
          }}
          secondary
          borderOnly
          check
          disabled={disabled}
          label={<label>{t('homeSurvey.stepPolicyConfirm')}</label>}
        />
      </Grid>
    </Grid>
  );
}
