/** @format */

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SurveyModalStep1 from './step1';
import SurveyModalStep2 from './step2';
import SurveyModalStep3 from './step3';
import SurveyModalStep4 from './step4';
import SurveyModalStep5 from './step5';

export default function SurveyModal() {
  const [step, setStep] = useState(1);
  const [group, setGroup] = useState();

  const handleCloseModal = () => {};

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={step !== 0}
      PaperProps={{
        style: {
          backgroundColor: '#fff',
          borderRadius: 10
        }
      }}
      onClose={handleCloseModal}
      aria-labelledby="max-width-dialog-title">
      <DialogContent>
        <Grid className="survey-body">
          {step === 1 && <SurveyModalStep1 setStep={setStep} />}
          {step === 2 && <SurveyModalStep2 setStep={setStep} group={group} setGroup={setGroup} />}
          {step === 3 && <SurveyModalStep3 group={group} setStep={setStep} />}
          {step === 4 && <SurveyModalStep4 setStep={setStep} />}
          {step === 5 && <SurveyModalStep5 setStep={setStep} />}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
