/** @format */

import * as React from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader';
import { Container } from 'src/components/container';
import { GetWidget } from 'src/components/widget';
// api
import useAuth from 'src/hooks/useAuth';
// eslint-disable-next-line no-unused-vars
import { getWidgetInfo } from 'src/slices/users';
import { useDispatch, useSelector } from 'src/store';
import { calcSize, calculateItemPositions, getBreakpoint } from 'src/utils/widget';
// import dummyData from './dummyData.json';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

let totalWidthOfItems = 0;
const changeTotalWidthOfItems = data => {
  totalWidthOfItems += data;
};
const generateLayout = (item, index, totalWidthOfItems, changeTotalWidthOfItems, itemPositions) => {
  const { size, x, y } = item;
  changeTotalWidthOfItems(calcSize(size, item).w);
  const layoutRenerated = {
    i: item.i || index + '',
    x: item.x != null ? item.x : itemPositions[index] !== null ? itemPositions[index].x : totalWidthOfItems % 12,
    y:
      item.y != null
        ? item.y
        : itemPositions[index] !== null
        ? itemPositions[index].y
        : Math.floor(totalWidthOfItems / 12),
    ...calcSize(size, item)
  };
  return layoutRenerated;
};
const defaultProps = {
  compactType: `vertical`,
  className: `widgetlayout`,
  rowHeight: 72,
  breakpoints: { xxl: 1784, xl: 1384, lg: 968, md: 712, sm: 520, xs: 0 },
  cols: { xxxl: 12, xxl: 12, xl: 12, lg: 12, md: 2, sm: 1, xs: 1 },
  margin: [32, 32],
  containerPadding: [0, 0],
  isDraggable: false,
  isResizable: false
};

const TicketsWidgetPreview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [globalLayouts, setLayouts] = React.useState({});
  const [itemPositions, setItemPositions] = React.useState([]);
  const [currentBreakPoint, setCurrentBreakPoint] = React.useState(getBreakpoint());
  const [widgets, setWidgets] = React.useState([
    {
      type: 'tickets',
      title: t('Tickets.yourSuportServices'),
      size: 'xwide',
      isNew: 0,
      w: 8,
      minW: null,
      maxW: null,
      h: ['md', 'sm', 'xs'].includes(getBreakpoint()) ? 5 : 4,
      minH: null,
      maxH: null
    }
  ]);

  React.useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    if (user) {
      dispatch(getWidgetInfo(lang));
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (widgets.length && itemPositions.length === 0) {
      setItemPositions(calculateItemPositions(widgets, totalWidthOfItems));
    }
    if (widgets.length && itemPositions.length) {
      const layoutArr = [];
      const newWigets = [];
      widgets.forEach((item, index) => {
        const dataGrid = generateLayout(item, index, totalWidthOfItems, changeTotalWidthOfItems, itemPositions);
        layoutArr.push(dataGrid);
        newWigets.push({
          ...item,
          x: dataGrid.x,
          y: dataGrid.y,
          w: dataGrid.w,
          minW: dataGrid.minW,
          maxW: dataGrid.maxW,
          h: dataGrid.h,
          minH: dataGrid.minH,
          maxH: dataGrid.maxH
        });
      });
      setWidgets(newWigets);
      if (currentBreakPoint) setLayouts({ ...globalLayouts, [currentBreakPoint]: layoutArr });
      else setLayouts({ ...globalLayouts, [Object.keys(globalLayouts)[0]]: layoutArr });
    }
  }, [widgets.length, currentBreakPoint, itemPositions]);

  const onLayoutChange = (currentLayout, allLayouts) => {
    // if (Object.keys(globalLayouts).length === 0)
    setLayouts(allLayouts);
  };

  const onBreakpointChange = (newBreakpoint, newCols) => {
    setCurrentBreakPoint(newBreakpoint);
  };

  return (
    <Container style={{ minHeight: 'calc(100vh - 200px)', marginTop: 32 }}>
      {widgets ? (
        <>
          <ResponsiveReactGridLayout
            {...defaultProps}
            layouts={globalLayouts}
            onLayoutChange={onLayoutChange}
            onBreakpointChange={onBreakpointChange}>
            {widgets.map((item, index, items) => {
              return (
                <div key={index} data-size={item.size}>
                  <GetWidget data={item} />
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        </>
      ) : (
        <Loader loading={true} />
      )}
    </Container>
  );
};

export default TicketsWidgetPreview;
