/** @format */

import React from 'react';
import { Box } from '@material-ui/core';
import { Dropdown } from 'antd';
import { AppsIcon } from '../icons/index.jsx';
import styles from './globalHeader.module.scss';
import useAuth from 'src/hooks/useAuth.js';
import { UserInfo } from './userInfo.jsx';
import { AppsDropdown } from './appsDropdown.jsx';

const GlobalHeader = props => {
  const { getSelectLang, userLang } = props;
  const { isAuthenticated, onLogin, logout, user } = useAuth();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Box className={styles.headerWrapper}>
      <Box className={`container`}>
        {/* <Dropdown
          overlayClassName={styles.dropDown}
          overlay={() => <AppsDropdown />}
          trigger={['click']}
          placement={'bottomRight'}>
          <Box component="span" onClick={e => e.preventDefault()} className="apps-icon">
            <AppsIcon color="#234d96" />
          </Box>
        </Dropdown> */}
        <div className={styles.userBox}></div>
        <div className={styles.userBox}>
          <UserInfo
            data={user}
            logout={handleLogout}
            onLangChange={data => {
              getSelectLang({ target: { value: data } });
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default GlobalHeader;
