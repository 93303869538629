/** @format */

import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import { updateUserInfo } from 'src/slices/users';
import { useDispatch, useSelector } from 'src/store';
import CancelCreateTicketModal from './cancel';
import { getFileBase64 } from 'src/utils/helper';
import { DeleteIcon, UploadIcon } from 'src/components/icons';
import { filesize } from 'filesize';
import { getTicketDetail, updateTicket } from 'src/slices/tickets';
import ErrorCreateTicket from './error';
import SuccessCreateTicket from './success';
import SuccessUpdateTicket from './successUpdate';

const initPostData = {
  incidentid: null,
  commentmessage: null,
  attachment: null,
  attachmentFileName: null
};

export default function UpdateTicketModal({ open, onClose, incidentid, ticketnumber }) {
  const { t } = useTranslation();
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [postData, setPostData] = React.useState(initPostData);
  const [isValidate, setIsValidate] = React.useState(false);
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);
  const [files, setFiles] = React.useState([]);
  const { tickets, updateTicketLoading } = useSelector(state => state.tickets);
  const [currentTicket, setCurrentTicket] = React.useState(null);

  React.useEffect(() => {
    setIsValidate(false);
    if (postData.commentmessage) setIsValidate(true);
  }, [postData]);

  React.useEffect(() => {
    if (tickets && incidentid && !ticketnumber) {
      setCurrentTicket(tickets.find(t => t.incidentid === incidentid));
    }
    if (ticketnumber) {
      setCurrentTicket({
        ticketnumber
      });
    }
    setPostData({ ...postData, incidentid });
  }, [tickets, incidentid, ticketnumber]);

  const handleClose = () => {
    if (!updateTicketLoading) {
      if (success) closeUpdateTicket();
      else setCancelModalOpen(true);
    }
  };
  const closeUpdateTicket = () => {
    if (!updateTicketLoading) {
      onClose();
      setCancelModalOpen(false);
      // // avoid flash of layout
      setTimeout(() => {
        setSuccess(false);
        setError(false);
        setFiles([]);
        // setCurrentTicket(null);
      }, 300);
    }
  };

  const updatePostData = (name, value) => {
    setPostData({ ...postData, [name]: value });
  };

  const handleSubmit = async () => {
    await dispatch(updateTicket(postData));

    setPostData(initPostData);
    setError(false);
    setSuccess(true);
    // setCurrentTicket(null);

    const Storage = window.localStorage;
    const lang = Storage.i18nextLng || 'en';
    await dispatch(getTicketDetail(lang, incidentid));
  };

  const handleSelectFiles = async e => {
    setFiles(files.concat(Array.from(e.target.files)));
    if (e.target.files && e.target.files[0]) {
      const attachmentFileName = e.target.files[0].name;
      const attachment = await getFileBase64(e.target.files[0]);
      setPostData({ ...postData, attachmentFileName, attachment });
    }
    // e.target.files = [];
    // e.target.value = null;
  };
  const removeFile = index => {
    files.splice(index, 1);
    setFiles([...files]);
    setPostData({ ...postData, attachmentFileName: null, attachment: null });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: 10
          }
        }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogActions>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          {currentTicket && (
            <div className="create-ticket-body">
              {error ? (
                <ErrorCreateTicket closeModal={closeUpdateTicket} />
              ) : success ? (
                <SuccessUpdateTicket ticketNumber={currentTicket.ticketnumber} closeModal={closeUpdateTicket} />
              ) : (
                <>
                  <Typography className="create-ticket-title">{t('Tickets.updateTicket')}</Typography>
                  <Typography className="create-ticket-id">
                    {t('Tickets.ticketNumber')} {currentTicket.ticketnumber}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid style={{ marginBottom: 20, marginTop: 20 }}>
                        <TextField
                          className="create-ticket-textarea"
                          multiline
                          fullWidth
                          label="Comment message *"
                          onChange={e => updatePostData('commentmessage', e.target.value)}
                          value={postData.commentmessage || ''}
                          variant="outlined"
                          minRows={8}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {files.length === 0 && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <Button noArrow borderOnly additionalClassName="visually-hidden-input">
                            <label htmlFor="attachment">
                              <UploadIcon /> Error Message/Screenshot (jpg, png)
                            </label>
                            <input
                              accept="image/png, image/gif, image/jpeg, image/jpg"
                              id="attachment"
                              type="file"
                              // multiple
                              onChange={handleSelectFiles}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {files.length > 0 && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <h4>Selected file</h4>
                          <div className="file-list">
                            {files.map((file, fileIndex) => (
                              <div className="file-item" key={`${fileIndex}-${file.name}-${file.size}`}>
                                {file.name} ({filesize(file.size)})
                                <button onClick={() => removeFile(fileIndex)}>
                                  <DeleteIcon />
                                </button>
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <div className="create-ticket-btns">
                    <Button
                      disabled={!isValidate || updateTicketLoading}
                      borderOnly
                      onClick={handleSubmit}
                      additionalClassName="btn-submit">
                      {updateTicketLoading ? t('personalData.loading') : t('Tickets.submit')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
      <CancelCreateTicketModal
        onClose={() => setCancelModalOpen(false)}
        open={cancelModalOpen}
        closeNewTicket={closeUpdateTicket}
      />
    </>
  );
}
